import { FormInstance, InputNumber, Popover, Radio, Select, Spin } from 'antd'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { debounce, sortBy } from 'lodash'
import axios from './../../../../helpers/axiosHelper'
import { selectCurrentService} from '../../../../store/servicesReducer'
import { ConnectionOptions } from '../ServiceDetailsForm'
import classes from './ConnectionFields.module.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetCitiesBySearchQueryThunk, GetPlaceDataThunk, GetPlaceNameByCoordinatesThunk, selectAllCountries, selectCityOptions, selectDistanceUnits, setCityOptions } from '../../../../store/locationsReducer'
import { InputGoogleAPI } from '../../../common/InputGoogleAPI/InputGoogleAPI'
import {ReactComponent as QuestionMarkIcon} from './../../../../img/icons/questionMark.svg'
import CombinedCoordinatesInput from '../../../common/CombinedCoordinatesInput/CombinedCoordinatesInput'
import SelectNode from './SelectNode/SelectNode'
import UserLocationConnectionBlock from './UserLocationConnectionBlock/UserLocationConnectionBlock'
import { LocationGoogleApiDataType } from '../../../../types/locationTypes'

const emptyCoordinateValues = {
  latitude: undefined,
  longitude: undefined,
  address: undefined,
}
const emptySetOfCoordinateValues = {
  latitude_north: undefined,
  latitude_south: undefined,
  longitude_east: undefined,
  longitude_west: undefined,
}
const emptyCoordinatesWithRadiusValues = {
  latitude: null,
  longitude: null,
  radius: null,
  unit: null,
}

const initialValues = {
  nodes: undefined,
  node_id_list: undefined,
  latitude: undefined,
  longitude: undefined,
  latitude_north: undefined,
  latitude_south: undefined,
  longitude_east: undefined,
  longitude_west: undefined,
  radius_area: undefined,
  is_global: false,
  address: undefined,
}

const ConnectionFields: React.FC<ConnectionFieldsPropTypes> = ({
  form,
  selectedConnectionOption,
  setSelectedConnectionOption,
  isEditing,
  setError
}) => {
  const handleConnectionTypeChange = (type: number):void => {
    form.setFieldsValue({...initialValues, is_global: type === ConnectionOptions.Global})
    setSelectedConnectionOption(type)
    setError('')
  }

  return (
    <>
      <div className={classes.blockTitle}>
        Select the option of where to display the service?
      </div>
      <div className={classes.optionsWrapper}>
        <Radio.Group
          value={selectedConnectionOption}
          onChange={(e) => handleConnectionTypeChange(e.target.value)}
          style={{marginBottom: '20px'}}
        >
          {Object.values(ConnectionOptions).filter(opt => typeof opt === 'string').map((opt, index) => (
            <Radio value={index} key={opt}>
              {opt}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      
      <div className={classes.fieldsWrapper}>
        {selectedConnectionOption === ConnectionOptions.Nodes && 
          <SelectNode form={form} isEditing={isEditing} initialValues={initialValues}/>
        }
        {selectedConnectionOption === ConnectionOptions.Coordinates &&
          <EnterCoordinates form={form} isEditing={isEditing}/>
        }
        {selectedConnectionOption === ConnectionOptions.City &&
          <SelectCity form={form} isEditing={isEditing}/>
        }
        {selectedConnectionOption === ConnectionOptions.Country &&
          <SelectCountry form={form} isEditing={isEditing}/>
        }
        {selectedConnectionOption === ConnectionOptions.Global &&
          <div className={classes.globalOptionText}>
            This service {isEditing ? 'is' : 'will be'} global
          </div>
        }
      </div>
      <UserLocationConnectionBlock
        form={form}
        isEditing={isEditing}
      />
    </>
  )
}

const EnterCoordinates: React.FC<ConnectionOptionPropTypes> = ({form, isEditing}) => {
  const dispatch = useAppDispatch()
  const distanceUnits = useAppSelector(selectDistanceUnits)
  const currentService = useAppSelector(selectCurrentService)

  const getAddressByCoordinates = async(coordinates: [number, number]) => {
    const resp = await dispatch(GetPlaceNameByCoordinatesThunk({lat: coordinates[0], lng: coordinates[1]}))
    return resp.payload as string
  }

  const [coordinateValues, setCoordinateValues] = useState<any>('')
  const [addressValue, setAddressValue] = useState<any>()
  const [coordinateValueSet, setCoordinateValueSet] = useState<any>(emptySetOfCoordinateValues)
  const [coordinatesWithRadiusValue, setCoordinatesWithRadiusValue] = useState<any>(emptyCoordinatesWithRadiusValues)

  useEffect(() => {
    if (!!isEditing && !!Object.keys(currentService)?.length) {
      if (!!currentService?.radius_area && Object.keys(currentService?.radius_area)) {
        setCoordinatesWithRadiusValue(currentService?.radius_area)
      } else if (!!currentService?.latitude_north) {
        setCoordinateValueSet({
          latitude_north: currentService?.latitude_north,
          latitude_south: currentService?.latitude_south,
          longitude_east: currentService?.longitude_east,
          longitude_west: currentService?.longitude_west,
        })
      } else if (!!currentService?.latitude) {
        setCoordinateValues(`${currentService?.latitude}, ${currentService?.longitude}`)
        currentService?.address
          ? setAddressValue(currentService.address)
          : getAddressByCoordinates([currentService?.latitude, currentService?.longitude!])
            .then(resp => setAddressValue(resp))
      }
    }
    // eslint-disable-next-line
  }, [isEditing, currentService])

  const handleCoordinateValueChange = (value: string, isValueCompleted: boolean, isValueEmpty: boolean) => {
    if (isValueCompleted) {
      const [latitude, longitude] = value.split(', ')
      getAddressByCoordinates([+latitude, +longitude])
      .then(resp => {
        setAddressValue(resp)
        form.setFieldsValue({...initialValues, latitude: +latitude, longitude: +longitude, address: resp})
      })
    } else if (isValueEmpty) {
      form.setFieldsValue({...initialValues, latitude: null, longitude: null, address: undefined})
      setAddressValue(undefined)
    }
    setCoordinateValues(value[value.length - 1] === ',' ? value + ' ': value)
    setCoordinatesWithRadiusValue(emptyCoordinatesWithRadiusValues)
    setCoordinateValueSet(emptySetOfCoordinateValues)
  }

  const handleCoordinateValueSetChange = (key: string, value: string) => {
    form.setFieldsValue({...initialValues, ...coordinateValueSet, [key]: value})
    setCoordinateValues(emptyCoordinateValues)
    setCoordinatesWithRadiusValue(emptyCoordinatesWithRadiusValues)
    setAddressValue(undefined)
    setCoordinateValueSet({...coordinateValueSet, [key]: value})
  }

  const handleAddressChange = async(value: string) => {
    if (value.length) {
      const addressResp = await dispatch(GetPlaceDataThunk({address: value}))
      const addressData = await (addressResp.payload as LocationGoogleApiDataType)
      form.setFieldsValue({...initialValues, latitude: addressData?.coordinates?.lat, longitude: addressData?.coordinates?.lng, address: value})
      setCoordinateValues(`${addressData?.coordinates?.lat}, ${addressData?.coordinates?.lng}`)
      setAddressValue(value)
    } else {
      form.setFieldsValue({latitude: null, longitude: null})
      setAddressValue(undefined)
      setCoordinateValues(emptyCoordinateValues)
    }
    setCoordinateValueSet(emptySetOfCoordinateValues)
    setCoordinatesWithRadiusValue(emptyCoordinatesWithRadiusValues)
  }

  const handleRadiusChange = (newValue: {[key: string]: string | undefined}) => {
    form.setFieldsValue({...initialValues, radius_area: {...coordinatesWithRadiusValue, ...newValue}})

    setCoordinateValues(emptyCoordinateValues)
    setCoordinateValueSet(emptySetOfCoordinateValues)
    setAddressValue(undefined)
    setCoordinatesWithRadiusValue({...coordinatesWithRadiusValue, ...newValue})
  }

  const handleCoordinateWithRadiusChange = (value: string, isValueCompleted: boolean, isValueEmpty: boolean) => {
    if (isValueCompleted) {
      const [latitude, longitude] = value.split(', ')
      handleRadiusChange({latitude: latitude, longitude: longitude})
    } else if (isValueEmpty) {
      handleRadiusChange({latitude: undefined, longitude: undefined})
    }
  }

  return (
    <>
      <div className={classes.optionExplanation}>
        Enter set of coordinates
        <Popover
          content={<div style={{textAlign: 'center', width: '350px'}}>Enter 4 values and the system will build a square area based on those coordinates. The service will be connected to this area.</div>}
        >
          <QuestionMarkIcon style={{marginLeft: '5px', cursor: 'pointer', width: '10px', position: 'absolute'}}/>
        </Popover>
      </div>
      <div className={classes.doubleInputArea}>
        <div>
          <div className={classes.explanationText}>
            Latitude (North)
          </div>
          <InputNumber
            placeholder='Enter Latitude (North)'
            onChange={(val) => handleCoordinateValueSetChange('latitude_north', val)}
            value={coordinateValueSet.latitude_north}
            style={{width: '100%'}}
            controls={false}
          />
        </div>
        <div>
          <div className={classes.explanationText}>
            Latitude (South)
          </div>
          <InputNumber
            placeholder='Enter Latitude (South)'
            onChange={(val) => handleCoordinateValueSetChange('latitude_south', val)}
            value={coordinateValueSet.latitude_south}
            style={{width: '100%'}}
            controls={false}
          />
        </div>
      </div>
      <div className={classes.doubleInputArea}>
        <div>
          <div className={classes.explanationText}>
            Longitude (East)
          </div>
          <InputNumber
            placeholder='Enter Longitude (East)'
            onChange={(val) => handleCoordinateValueSetChange('longitude_east', val)}
            value={coordinateValueSet.longitude_east}
            style={{width: '100%'}}
            controls={false}
          />
        </div>
        <div>
          <div className={classes.explanationText}>
            Longitude (West)
          </div>
          <InputNumber
            placeholder='Enter Longitude (West)'
            onChange={(val) => handleCoordinateValueSetChange('longitude_west', val)}
            value={coordinateValueSet.longitude_west}
            style={{width: '100%'}}
            controls={false}
          />
        </div>
      </div>
      <div className={classes.optionExplanation}>
        Or
      </div>
      <div className={classes.optionExplanation}>
        Enter coordinates and radius
      </div>
      <CombinedCoordinatesInput
        onChange={handleCoordinateWithRadiusChange}
        initialValue={coordinatesWithRadiusValue?.latitude ? `${coordinatesWithRadiusValue?.latitude}, ${coordinatesWithRadiusValue?.longitude}` : undefined}
      />
      {!!form.getFieldValue('radius_area') && !!form.getFieldValue('radius_area')?.latitude && !!form.getFieldValue('radius_area')?.longitude &&
        <div className={classes.additionalInfoWrapper} style={{marginBottom: '10px'}}>
          <div className={classes.additionalInfo}>Latitude: {form.getFieldValue('radius_area')?.latitude}</div>
          <div className={classes.additionalInfo}>Longitude: {form.getFieldValue('radius_area')?.longitude}</div>
        </div>
      }
      <div className={classes.doubleInputArea}>
        <div>
          <div className={classes.explanationText}>
            Radius
          </div>
          <InputNumber
            placeholder='Enter Radius'
            onChange={(val) => handleRadiusChange({radius: val})}
            value={coordinatesWithRadiusValue.radius}
            style={{width: '100%'}}
            controls={false}
          />
        </div>
        <div>
          <div className={classes.explanationText}>
            Units
          </div>
          <Select
            style={{width: '100%'}}
            placeholder='Select Units'
            value={coordinatesWithRadiusValue.unit}
            onChange={(val) => handleRadiusChange({unit: val})}
          >
            {distanceUnits.map(unit => <Select.Option key={unit} value={unit}>{unit}</Select.Option>)}
          </Select>
        </div>
      </div>

      <div className={classes.optionExplanation}>
        Or
      </div>
      <div className={classes.optionExplanation}>
        Enter address or it's coordinates
      </div>
      <div>
        <div className={classes.explanationText}>
          Address
        </div>
        <InputGoogleAPI 
          placeholder='Enter Address'
          onSelect={(value: string) => handleAddressChange(value)}
          value={addressValue}
        />
      </div>
      <CombinedCoordinatesInput
        onChange={handleCoordinateValueChange}
        initialValue={coordinateValues}
      />
      {!!form.getFieldValue('latitude') && !!form.getFieldValue('longitude') &&
        <div className={classes.additionalInfoWrapper}>
          <div className={classes.additionalInfo}>Latitude: {form.getFieldValue('latitude')}</div>
          <div className={classes.additionalInfo}>Longitude: {form.getFieldValue('longitude')}</div>
        </div>
      }
    </>
  )
}

const SelectCity: React.FC<ConnectionOptionPropTypes> = ({form, isEditing}) => {
  const dispatch = useAppDispatch()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const currentService = useAppSelector(selectCurrentService)
  const cityOptions = useAppSelector(selectCityOptions)

  const [searchQuery,  setSearchQuery] = useState('')
  const [selectedValues, setSelectedValues] = useState<{label: string, value: number}[]>([])
  const [isLoading,  setIsLoading] = useState(false)

  useEffect(() => {
    if (!!searchQuery.length) {
      setIsLoading(true)
      dispatch(GetCitiesBySearchQueryThunk({searchQuery, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, searchQuery])

  useEffect(() => {
    if (!!isEditing && !!currentService?.nodes?.length && currentService?.nodes?.[0]?.label?.toLowerCase() === 'city') {
      setSelectedValues(currentService?.nodes.map(node => ({label: node.name, value: node.id})))
      form.setFieldsValue({
        nodes: currentService?.nodes?.map(n => n.id),
        node_id_list: currentService?.nodes?.map(n => n.id)
      })
    } else {
      setSelectedValues([])
    }
  }, [currentService, isEditing, form])

  const handleSelect = (selectedValue: {value: number, label: string}) => {
    const updatedValues = [...selectedValues, selectedValue]
    form.setFieldsValue({...initialValues, node_id_list: updatedValues.map(val => val.value)})
    setSelectedValues(updatedValues)
    setSearchQuery('')
    dispatch(setCityOptions([]))
  }

  const handleDeselect = ({value}: {value: number}) => {
    const updatedValues = selectedValues.filter(val => val.value !== value)
    form.setFieldsValue({...initialValues, node_id_list: updatedValues.map(val => val.value)})
    setSelectedValues(updatedValues)
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchQuery: string) => {
      setSearchQuery(searchQuery)
    }, 350),
    []
  )

  return (
    <>
      <div className={classes.explanationText}>
        Select City (You can select one or many cities)
      </div>
      <Select
        placeholder='Please select city'
        showSearch
        mode='multiple'
        onSelect={(_: any, option: {value: number, children: string}) => handleSelect({value: option.value, label: option.children})}
        onDeselect={handleDeselect}
        onSearch={handleSearchDebounce}
        style={{width: '65%'}}
        filterOption={false}
        value={selectedValues}
        labelInValue
        notFoundContent={isLoading ? (
          <Spin size='small' />
        ) : (
          <>
            {!!searchQuery.length && !cityOptions.length && 'No results found'}
            {!searchQuery.length && !cityOptions.length && 'Start typing city name'}
          </>
        )}
      >
        {cityOptions.map((optionGroup) => (
           <Select.OptGroup
            label={optionGroup.name}
            key={optionGroup.id}
          >
            {optionGroup.cities.map(c => (
              <Select.Option value={c.id} key={c.id}>
                {c.name}
              </Select.Option>
            ))}
         </Select.OptGroup>
        ))}
      </Select>
    </>
  )
}

const SelectCountry: React.FC<ConnectionOptionPropTypes> = ({form, isEditing}) => {
  const countries = useAppSelector(selectAllCountries)
  const currentService = useAppSelector(selectCurrentService)
  const [selectedValue, setSelectedValue] = useState<number>()

  useEffect(() => {
    if (!!isEditing && !!currentService?.nodes?.length && currentService?.nodes?.[0]?.label?.toLowerCase() === 'country') {
      setSelectedValue(currentService?.nodes?.[0]?.id)
    } else {
      setSelectedValue(undefined)
    }
  }, [currentService, isEditing, form])

  const handleSelect = (id: number) => {
    setSelectedValue(id)
    form.setFieldsValue({...initialValues, node_id_list: [id]})
  }

  return (
    <>
      <div className={classes.explanationText}>
        Select Country
      </div>
      <Select
        placeholder='Please select country'
        showSearch
        filterOption={(input, option) => {
          const countryName = option!.children as unknown as string
          return countryName?.toLowerCase().includes(input?.toLowerCase())
        }}
        onSelect={handleSelect}
        style={{width: '65%'}}
        value={selectedValue}
      >
        {sortBy(countries, country => country.name).map(country => (
          <Select.Option value={country.id} key={country.code}>
            {country.name}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

interface ConnectionFieldsPropTypes {
  selectedConnectionOption: number
  setSelectedConnectionOption: Dispatch<SetStateAction<ConnectionOptions>>
  form: FormInstance
  isEditing: boolean
  setError: Dispatch<SetStateAction<string>>
}

interface ConnectionOptionPropTypes {
  form: FormInstance
  isEditing: boolean
}

export default ConnectionFields
