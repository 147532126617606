import { DatePicker, Form, FormInstance, Radio } from 'antd'
import { Dispatch, SetStateAction, useEffect } from 'react'
import classes from './../AdvertisingDetailsForm.module.css'
import localClasses from './MainData.module.css'
import InputField from '../../../common/formFields/InputField'
import AddBannerHTMLCodeBlock from './AddBannerHTMLCodeBlock/AddBannerHTMLCodeBlock'
import UploadBannerBlock from './UploadBannerBlock/UploadBannerBlock'
import GenerateBannerBlock from './GenerateBannerBlock/GenerateBannerBlock'
import { ImagePreviewType } from '../../../../types/appTypes'
import { selectCurrentAdvertisement } from '../../../../store/advertisingReducer'
import { useAppSelector } from '../../../../app/hooks'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const MainData: React.FC<MainDataPropTypes> = ({
  form,
  isEditing,
  isCopy,
  setSelectedBannerOption,
  selectedBannerOption,
  setUploadedBannerPreview,
  uploadedBannerPreview,
  isTargetingFieldsDisabled,
}) => {
  const currentAdvertisement = useAppSelector(selectCurrentAdvertisement)
  const company_name = Form.useWatch('company_name', form)
  const link = Form.useWatch('link', form)

  useEffect(() => {
    if (isEditing || isCopy) {
      if(!!currentAdvertisement?.banner_url && !uploadedBannerPreview) {
        setUploadedBannerPreview({url: currentAdvertisement?.banner_url, uid: '1'})
      }
    }
    // eslint-disable-next-line
  }, [currentAdvertisement, isEditing, uploadedBannerPreview, form])

  const isDateDisabled = (type: 'start' | 'end', date: Dayjs) => {
    if ((type === 'start' && !form.getFieldValue('end_date')) || (type === 'end' && !form.getFieldValue('start_date'))) {
      return date.isBefore(dayjs().startOf('day'))
    } else if (type === 'start' && !!form.getFieldValue('end_date')) {
      return date.isAfter(form.getFieldValue('end_date')) || date.isBefore(dayjs().startOf('day'))
    } else {
      return date.isBefore(form.getFieldValue('start_date')) || date.isBefore(dayjs().startOf('day'))
    }
  }

  const onBannerOptionChange = (option: 'uploaded' | 'generated' | 'html') => {
    setSelectedBannerOption(option)
    form.setFieldValue('selected_banner_type', option)
  }

  return (
    <div className={localClasses.mainDataWrapper}>
      <div className={classes.formBlock}>
        <div className={classes.doubleInputArea}>
          <div style={{position: 'relative'}}>
            <InputField
              name='company_name'
              requiredMessage={`Please enter company name!`}
              placeholder='Enter Company Name'
              label={`Company Name`}
              maxLength={80}
            />
            <div style={{fontSize: '10px', color: 'gray', position: 'absolute', bottom: '5px', right: '0px'}}>
              {company_name?.length || 0} / 80
            </div>
          </div>
          <div style={{position: 'relative'}}>
            <InputField
              name='link'
              requiredMessage={`Please enter link!`}
              placeholder='Enter Link'
              label={`Website Link (a link to the site that users will go to)`}
              // eslint-disable-next-line
              rules={[{pattern: new RegExp(/^(https?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i), message: 'Invalid link format'}]}
              maxLength={500}
            />
            <div style={{fontSize: '10px', color: 'gray', position: 'absolute', bottom: '5px', right: '0px'}}>
              <span>
                Format: link.com
              </span>
              <span style={{margin: '0px 8px'}}>
              |
              </span>
              {link?.length || 0} / 500
            </div>
          </div>
        </div>
        <div className={classes.label}>
          Advertising banner
        </div>
        <Radio.Group
          onChange={e => onBannerOptionChange(e.target.value)}
          value={selectedBannerOption}
          style={{marginTop: '8px', marginBottom: '20px'}}
        >
          <Radio value='uploaded'>Upload a banner</Radio>
          <Radio value='generated'>Generate a banner </Radio>
          <Radio value='html'>HTML code</Radio>
        </Radio.Group>
        {selectedBannerOption === 'uploaded' &&
          <UploadBannerBlock
            form={form}
            preview={uploadedBannerPreview}
            setPreview={setUploadedBannerPreview}
            isEditing={isEditing}
          />
        }
        {selectedBannerOption === 'generated' &&
          <GenerateBannerBlock
            form={form}
            preview={uploadedBannerPreview}
            setPreview={setUploadedBannerPreview}
            isEditing={isEditing}
          />
        }
        {selectedBannerOption === 'html' &&
          <AddBannerHTMLCodeBlock form={form} isEditing={isEditing}/>
        }
      </div>
      <div className={classes.formBlock}>
        <div className={classes.blockTitle}>
          Select the dates for the ads to be shown 
        </div>
        <div className={classes.label}>
          Start
        </div>
        <Form.Item
          name='start_date'
          rules={[{required: true, message:'Please select start date'}]}
        >
          <DatePicker
            style={{width: '100%'}}
            disabledDate={(date) => isDateDisabled('start', date)}
            disabled={isEditing || isTargetingFieldsDisabled}
            format={date => date?.utc && date?.utc()?.format('DD.MM.YYYY')}
          />
        </Form.Item>
        <div className={classes.label}>
          End
        </div>
        <Form.Item
          name='end_date'
          rules={[{required: true, message:'Please select end date'}]}
        >
          <DatePicker
            style={{width: '100%'}}
            disabledDate={(date) => isDateDisabled('end', date)}
            disabled={isEditing || isTargetingFieldsDisabled}
            format={date => date && date?.utc()?.format('DD.MM.YYYY')}
          />
        </Form.Item>
      </div>
    </div>
  )
}

interface MainDataPropTypes {
  form: FormInstance
  isEditing: boolean
  isCopy: boolean
  setSelectedBannerOption: Dispatch<SetStateAction<'uploaded' | 'generated' | 'html'>>
  selectedBannerOption: 'uploaded' | 'generated' | 'html'
  setUploadedBannerPreview: Dispatch<SetStateAction<ImagePreviewType | null>>
  uploadedBannerPreview: ImagePreviewType | null
  isTargetingFieldsDisabled?: boolean
}

export default MainData
