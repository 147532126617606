import { FormInstance, Input } from 'antd'
import { useEffect, useState } from 'react'
import classes from './DynamicCountInput.module.css'

const DynamicCountInput: React.FC<DynamicCountInputPropTypes> = (props) => {
  const [fieldValues, setFieldValues] = useState(props.values)

  useEffect(() => {
    setFieldValues(props.values)
  }, [props])

  const handleFieldChange = (valueIndex: number, newValue: string) => {
    const updatedValues = fieldValues.map((value, index) => {
      if (index === valueIndex) {
        return newValue
      } else {
        return value
      }
    })
    setFieldValues(updatedValues)
    props.onChange(updatedValues)
    props.form.setFieldsValue({[props.formValueKey]: updatedValues})
  }

  const handleRemoveField = (value: string) => {
    const updatedValues = fieldValues.filter(val => val !== value)
    props.form.setFieldsValue({[props.formValueKey]: updatedValues})
    setFieldValues(updatedValues)
    props.onChange(updatedValues)
  }

  const handleAddField = () => {
    setFieldValues([...fieldValues, ''])
    props.onChange([...fieldValues, ''])
  }

  return (
    <>
      <div className={classes.label}>
        {props.label}
      </div>
      {fieldValues.map((value, index) => (
        <Input
          placeholder={props.placeholder}
          onChange={(e) => handleFieldChange(index, e.target.value)}
          value={value}
          key={index}
          style={{marginBottom:'20px'}}
          addonAfter={
            fieldValues.length > 1 ? (
              <div
                onClick={() => handleRemoveField(value)}
                className={classes.addRemoveFieldBtn}
              >
                X
              </div>
            ) : null
          }
        />
      ))}
      <div
        className={classes.addRemoveFieldBtn}
        onClick={handleAddField}
      >
        + Add
      </div>
    </>
  )
}

interface DynamicCountInputPropTypes {
  label: string
  placeholder: string
  values: string[]
  form: FormInstance
  formValueKey: string
  onChange: (v: string[]) => void
}

export default DynamicCountInput
