import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Button, Spin } from 'antd'
import classes from './WidgetDetailsPage.module.css'
import axios from './../../../helpers/axiosHelper'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetWidgetByCodeThunk, selectCurrentWidget } from '../../../store/widgetsReducer'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import dayjs from 'dayjs'

const WidgetDetailsPage = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentWidget = useAppSelector(selectCurrentWidget)

  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    // pathname example: /widgets/view/111
    const code = location.pathname.split('/view/')[1]

    setIsDataLoading(true)
    dispatch(GetWidgetByCodeThunk(code))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch])

  if (isDataLoading) {
    return <Spin style={{width: '100%'}}/>
  }

  return (

    <>
      <div>
        <Breadcrumbs />
        <h1>
          {currentWidget?.location?.name}
        </h1>
      </div>

      <div className={classes.mainInfoArea}>
        <div className={classes.formBlock}>
          <div className={classes.field}>
            <div className={classes.label}>Location</div>
              <div className={classes.fieldValue}>
                {currentWidget?.location?.name}
              </div>
            {/* <Input value={currentWidget?.location?.name}/> */}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>Creator</div>
              <div className={classes.fieldValue}>
                {currentWidget?.creator_name}
              </div>
            {/* <Input value={currentWidget?.creator_name}/> */}
          </div>

          <div className={classes.doubleField}>
            <div className={classes.field}>
              <div className={classes.label}>Width</div>
              <div className={classes.fieldValue}>
                {currentWidget?.width ? currentWidget?.width + 'px' : '100%'}
              </div>
            </div>
            <div className={classes.field}>
              <div className={classes.label}>Height</div>
              <div className={classes.fieldValue}>
                {currentWidget?.height ? currentWidget?.height + 'px' : '100%'}
              </div>
            </div>
          </div>

          <div className={classes.field}>
            <div className={classes.label}>Date of creation</div>
            <div className={classes.fieldValue}>
              {dayjs(currentWidget?.created_date).format('DD.MM.YYYY')}
            </div>
            {/* <DatePicker
              value={dayjs(currentWidget?.created_date)}
              allowClear={false}
              style={{width: '100%'}}
            /> */}
          </div>
        </div>

        <div className={classes.statisticWrapper}>
          {currentWidget?.interaction_quantities?.map(i => (
            <div key={i.id.action_id} className={classes.statisticCard}>
              <div className={classes.statisticTitle}>
                Activity by {i.actionName}
              </div>
              <div className={classes.statisticValueWrapper}>
                <div className={classes.value}>
                  {i.interactionCount}
                </div>
                <div className={classes.name}>
                  {i.interactionCount === 1 ? 'user' : 'users'}
                </div>
              </div>
            </div>
          ))}
        </div>

        <Link to='/widgets' className={classes.cancelBtn}>
          <Button>
            Cancel
          </Button>
        </Link>
      </div>
    </>
  )
}

export default WidgetDetailsPage
