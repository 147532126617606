import { Checkbox, Form, FormInstance } from 'antd'
import { useEffect, useState } from 'react'
import classes from './../ServiceDetailsForm.module.css'
import InputField from '../../../common/formFields/InputField'
import DynamicCountInput from '../../../common/DynamicCountInput/DynamicCountInput'
import { selectCurrentService } from '../../../../store/servicesReducer'
import { useAppSelector } from '../../../../app/hooks'

const ContactFields: React.FC<ContactFieldsPropTypes> = ({isEditing, form, bannerColor, selectedBannerOption}) => {
  const currentService = useAppSelector(selectCurrentService)
  const [phoneFieldValues, setPhoneFieldValues] = useState([''])
  
  useEffect(() => {
    if (!!isEditing && !!currentService?.phones?.length && phoneFieldValues[0] === '') {
      setPhoneFieldValues(currentService?.phones)
      form.setFieldsValue({phones: currentService?.phones})
    }
  }, [isEditing, form, phoneFieldValues, currentService])

  return (
    <div className={classes.formBlock}>
      <div className={classes.checkboxWrapper}>
        <Form.Item name='is_active' valuePropName='checked'>
          <Checkbox />
        </Form.Item>
        <div className={classes.label}>
          Service is active for search results
        </div>
      </div>
      <InputField
        name='website'
        placeholder='Enter Website'
        label='Website'
      />
      <DynamicCountInput
        label='Phone'
        placeholder='Enter Phone'
        values={phoneFieldValues}
        onChange={(v) => setPhoneFieldValues(v)}
        form={form}
        formValueKey='phones'
      />
      {selectedBannerOption === 'generated' &&
        <div style={{marginTop: '15px'}}>
          <div className={classes.label}>
            Service Banner Color
          </div>
          <div className={classes.bannerColorWrapper}>
            <div className={classes.bannerColorSample} style={{backgroundColor: bannerColor}}>
            </div>
            - {bannerColor}
          </div>
          <div style={{fontSize: '10px', color: 'rgba(0, 0, 0, 0.85)'}}>
            If you want to change the color of the banner - upload another logo.
          </div>
        </div>
      }
    </div>
  )
}

interface ContactFieldsPropTypes {
  isEditing: boolean
  form: FormInstance
  bannerColor: string
  selectedBannerOption: 'generated' | 'html'
}

export default ContactFields
