import React, { useEffect, useState } from 'react'
import { Form, FormInstance, Rate, Select, TimePicker, Input, Spin } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import axios from '../../../../helpers/axiosHelper'
import classes from './MainInformationTab.module.css'
import {ReactComponent as WorkingHoursIcon} from './../../../../img/airportDetailsForm/workingHoursIcon.svg'
import {ReactComponent as RateIcon} from './../../../../img/airportDetailsForm/rateIcon.svg'
import InputField from '../../formFields/InputField'
import Map from '../../Map/Map'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectAllCountries, setCities } from '../../../../store/locationsReducer'
import { sortBy } from 'lodash'
import DynamicCountInput from '../../DynamicCountInput/DynamicCountInput'
import CityField, { GoogleCityField } from '../../formFields/CityField/CityField'
import { GetAllPortsThunk, selectPortList, setPorts } from '../../../../store/portsReducer'

dayjs.extend(duration)

const MainInformationTab: React.FC<MainInformationTabPropTypes> = ({form, transportName, isCodeOptional, isEditing, itemId}) => {
  const [mapCenter, setMapCenter] = useState<[number, number] | []>([])
  const [coordinates, setCoordinated] = useState('')

  const latitudeValue = Form.useWatch('latitude', form)
  const longitudeValue = Form.useWatch('longitude', form)

  useEffect(() => {
    if (!!latitudeValue && !!longitudeValue && !coordinates.length) {
      setCoordinated(`${latitudeValue}, ${longitudeValue}`)
      setMapCenter([+latitudeValue, +longitudeValue])
    }
  }, [longitudeValue, latitudeValue, coordinates])

  const handleCoordinatesChange = (coordinates: string) => {
    // coordinates example: "50.49085592975587, 30.486457024483787"
    const containsOnlyAllowedSymbols = /[^a-zA-z]+$|^$/g.test(coordinates)
    if (!!containsOnlyAllowedSymbols) {
      setCoordinated(coordinates[coordinates.length - 1] === ',' ? coordinates + ' ': coordinates)
    }

    const isFullCoordinatesString = /-?(\d*\.)?\d+,\s*-?(\d*\.)\d+/.test(coordinates) && !!containsOnlyAllowedSymbols
    if (isFullCoordinatesString) {
      const [latitude, longitude] = coordinates.split(', ')
      form.setFieldsValue({latitude, longitude})
      setMapCenter([+latitude, +longitude])
    } else if (!coordinates.length) {
      form.setFieldsValue({latitude: null, longitude: null})
      setMapCenter([0, 0])
    }
  }

  const handleMapMarkerMove = (coordinates: {lat: number, lng: number}) => {
    form.setFieldsValue({'latitude': coordinates.lat, 'longitude': coordinates.lng})
    setCoordinated(`${coordinates.lat}, ${coordinates.lng}`)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.fieldsArea}>
        <GeneralInfoBlock
          form={form}
          coordinates={coordinates}
          handleCoordinatesChange={handleCoordinatesChange}
          transportName={transportName}
          isCodeOptional={isCodeOptional}
          isEditing={isEditing}
          itemId={itemId}
        />
        {/* <ContactInfoBlock form={form} isEditing={isEditing} coordinates={coordinates}/> */}
        {/* <AdditionalInfoBlock form={form}/> */}
      </div>
      <div className={classes.mapWrapper}>
        <Map
          center={mapCenter.length ? mapCenter : [0, 0]}
          showMarker={!!mapCenter.length}
          className={classes.map}
          onMarkerMove={handleMapMarkerMove}
        />
      </div>
    </div>
  )
}

const GeneralInfoBlock: React.FC<GeneralInfoBlockPropTypes> = ({form, coordinates, handleCoordinatesChange, transportName, isCodeOptional, isEditing, itemId}) => {
  const dispatch = useAppDispatch()
  const countries = useAppSelector(selectAllCountries)
  const ports = useAppSelector(selectPortList)

  const [selectedCountry, setSelectedCountry] = useState('')
  const [parentPortName, setParentPortName] = useState('')
  const [isPortListLoading, setIsPortListLoading] = useState(false)

  const handleCountryChange = (val: string) => {
    setSelectedCountry(val)
  }

  useEffect(() => {
    if (selectedCountry.length) {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      form.setFieldsValue({city_name: null})
      dispatch(setCities([]))
      return () => {source.cancel()}
    }
  }, [selectedCountry, dispatch, form])

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if (!!parentPortName.length) {
      dispatch(setPorts([]))
      setIsPortListLoading(true)
      dispatch(GetAllPortsThunk({fetchParams: {search_param: 'NAME', name: parentPortName, search_filter: 'ALL'}, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsPortListLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, parentPortName])

  const countryCodeValue = Form.useWatch('country_code', form)

  return (
    <div className={classes.formBlock}>
      {transportName === 'port' &&
        <Form.Item name='country_code' style={{display: 'none'}}></Form.Item>
      }
      <div className={transportName === 'port' ? classes.doubleInputArea : ''}>
        <InputField
          name='name'
          requiredMessage={`Please enter ${transportName} name!`}
          placeholder='Enter Name'
          label={`Name ${transportName.charAt(0).toUpperCase() + transportName.slice(1)}`}
        />
        {transportName === 'port' &&
          <div>
            <div className={classes.label}>
              Port type
            </div>
            <Form.Item
              name='port_type'
              rules={[{ required: true, message: 'Please select type!' }]}
            >
              <Select
                placeholder='Please select'
                onChange={(val) => {
                  const codeLength = form.getFieldValue('code')?.length
                  if (val === 'WAYPOINT' || val === 'EXCURSION') {
                    form.setFields([{
                      name: 'code',
                      errors: !codeLength ? [] : codeLength > 3 ? [] : ['Code must include 3 letters']
                    }])
                  }
                }}
              >
                <Select.Option value={'PORT'}>Port</Select.Option>
                <Select.Option value={'WAYPOINT'}>Waypoint</Select.Option>
                <Select.Option value={'EXCURSION'}>Excursion</Select.Option>
                <Select.Option value={'GENERIC'}>Generic</Select.Option>
              </Select>
            </Form.Item>
          </div>
        }
      </div>
      <div className={transportName.includes('station') ? '' :  classes.doubleInputArea}>
        <InputField
          name='code'
          addonBefore={transportName === 'port' ? countryCodeValue : undefined}
          requiredMessage={!!isCodeOptional 
            ? undefined 
            : transportName === 'airport' ? 'Please enter IATA' : `Please enter ${transportName} code!`
          }
          placeholder={`Enter ${transportName === 'airport' ? 'IATA' : 'Code'}`}
          label={transportName === 'airport' ? 'IATA' : 'Code'}
          rules={[
            (form) => {
              const value = form.getFieldValue('code') as string
              return value === 'N' ? {} : {min: 3, message: `${transportName === 'airport' ? 'IATA' : 'Code'} must include 3 letters`}
            }
          ]}
          maxLength={3}
        />
        <div>
          {transportName === 'airport' ? (
            <InputField
              name='icao'
              placeholder={`Enter ICAO`}
              label={'ICAO'}
              maxLength={4}
            />
          ) : transportName.includes('station') ? (
            <></>
          ) : (
            <>
              <div className={classes.label}>
                Category
              </div>
              <Form.Item
                name='category'
                rules={[{ required: true, message: 'Please select category!' }]}
              >
                <Select placeholder='Please select'>
                  <Select.Option value={-1}>Don’t display</Select.Option>
                  <Select.Option value={0}>No scheduled service</Select.Option>
                  <Select.Option value={1}>{`Small ${transportName}`}</Select.Option>
                  <Select.Option value={2}>{`Medium ${transportName}`}</Select.Option>
                  <Select.Option value={3}>{`Large ${transportName} (hub)`}</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
        </div>
      </div>
      {transportName === 'airport' &&
        <>
          <div className={classes.label}>
            Category
          </div>
          <Form.Item
            name='category'
            rules={[{ required: true, message: 'Please select category!' }]}
          >
            <Select placeholder='Please select'>
              <Select.Option value={-1}>Don’t display</Select.Option>
              <Select.Option value={0}>No scheduled service</Select.Option>
              <Select.Option value={1}>{`Small ${transportName}`}</Select.Option>
              <Select.Option value={2}>{`Medium ${transportName}`}</Select.Option>
              <Select.Option value={3}>{`Large ${transportName} (hub)`}</Select.Option>
            </Select>
          </Form.Item>
        </>
      }
      {transportName === 'port' &&
        <div className={classes.doubleInputArea}>
          <div>
            <div className={classes.label}>
              Cruise port
            </div>
            <Form.Item
              name='is_cruise'
              rules={[{ required: true, message: 'Please select yes/no!' }]}
            >
              <Select placeholder='Please select yes/no'>
                <Select.Option value={true}>Yes</Select.Option>
                <Select.Option value={false}>No</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div>
            <div className={classes.label}>
              Parent port
            </div>
            <Form.Item name='parent_port_id'>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder='Start typing port name'
                onSearch={searchQuery => /^[a-zA-Z0-9 ]*$/.test(searchQuery) && setParentPortName(searchQuery)}
                onSelect={(_: any, option: {value: number, key: string}) => {
                  form.setFieldValue('parent_port_id', {value: option.value, label: option.key})
                  setParentPortName('')
                  dispatch(setPorts([]))
                }}
                searchValue={parentPortName}
                labelInValue={true}
                allowClear
                onClear={() => {
                  form.setFieldValue('parent_port_id', null)
                  setParentPortName('')
                  dispatch(setPorts([]))
                }}
                // value={parentPortValue} 
                filterOption={false}
                notFoundContent={isPortListLoading ? (
                  <Spin size='small' />
                ) : (
                  <>
                    {!!parentPortName.length && !ports.length && 'No results found'}
                    {!parentPortName.length && !ports.length && 'Start typing port name'}
                  </>
                )}
              >
                {sortBy(ports, port => port.name)
                  .filter((p:any) => p.id !== itemId)
                  .map((p:any) => (
                    <Select.Option key={`${p.name}${p.code ? '('+p.code+')' : ''}`} value={p.id}>
                      {p.name} {p.code ? '('+p.code+')' : ''}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      }
      {transportName === 'airport'  || transportName === 'port' ? (
        <GoogleCityField
          form={form}
          countryCodeValue={countryCodeValue}
          label='City'
          style={{width: '100%'}}
          isEditing={isEditing}
          transportName={transportName}
        />
      ) : (
        <div className={classes.doubleInputArea}>
          <div>
            <div className={classes.label}>
              Country
            </div>
            <Form.Item
              name='country_code'
              rules={[{ required: true, message: 'Please select country!' }]}
            >
              <Select
                placeholder='Please select country'
                showSearch
                filterOption={(input, option) => {
                  const countryName = option!.children as unknown as string
                  return countryName?.toLowerCase().includes(input.toLowerCase())
                }}
                onChange={(val) => handleCountryChange(val)}
              >
                {sortBy(countries, country => country.name).map(country => (
                  <Select.Option value={country.code} key={country.code}>{country.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <CityField form={form} countryCodeValue={countryCodeValue} />
        </div>
      )}
      <div style={{marginBottom: '20px'}}>
        <div className={classes.label}>
          Coordinates
        </div>
        <Input
          placeholder='Enter coordinates (example: 50.49085592975587, 30.486457024483787)'
          onChange={(e) => handleCoordinatesChange(e.target.value)}
          value={coordinates}
        />
      </div>
      <div className={classes.doubleInputArea}>
        <InputField
          name='latitude'
          label='Latitude'
          disabled
          requiredMessage='Latitude is empty!'
        />
        <InputField
          name='longitude'
          label='Longitude'
          disabled
          requiredMessage='Longitude is empty!'
        />
      </div>
    </div>
  )
}

const ContactInfoBlock: React.FC<{form: FormInstance, isEditing: boolean, coordinates: any}> = ({form, isEditing, coordinates}) => {
  const [phoneFieldValues, setPhoneFieldValues] = useState([''])
  const ratingValue = Form.useWatch('rating', form)

  useEffect(() => {
    if (isEditing && !!form.getFieldValue('phones')?.length && phoneFieldValues[0] === '') {
      setPhoneFieldValues(form.getFieldValue('phones'))
    }
  }, [isEditing, form, coordinates, phoneFieldValues])

  return (
    <div className={classes.formBlock}>
      <div className={classes.smallFormBlock}>
        <div className={classes.formIconWrapper}>
          <RateIcon/>
        </div>
        <div className={classes.label}>
          Rate
        </div>
        <div className={classes.value}>
          {ratingValue || 0}
        </div>
        <Form.Item name='rating'>
          <Rate />
        </Form.Item>
      </div>

      <InputField
        name='website'
        placeholder='Add your link'
        label='Website'
        addonBefore='http://'
      />

      {/* <InputField
        name='email'
        rules={[{ type: 'email', message: 'Invalid e-mail!' }]}
        requiredMessage='Please enter email!'
        placeholder='Enter Email'
        label='Email'
      /> */}

      <InputField
        name='comment'
        placeholder='Add your comment'
        label='Comment'
      />

      <DynamicCountInput
        label='Phone'
        placeholder='Enter Phone'
        values={phoneFieldValues}
        form={form}
        formValueKey='phones'
        onChange={(v) => setPhoneFieldValues(v)}
      />
    </div>
  )
}

const AdditionalInfoBlock: React.FC<MainInformationTabPropTypes> = ({form}) => {
 const workingHoursValue = Form.useWatch('working_hours', form) || 0
 const workingHoursInHours = dayjs.duration(workingHoursValue?.[1]?.diff(workingHoursValue?.[0])).hours()
 const rateValue = Form.useWatch('rate', form) || 0

  return (
    <div>
      <div className={classes.smallFormBlock} style={{marginBottom:'30px'}}>
        <div>
          <div className={classes.formIconWrapper}>
            <WorkingHoursIcon/>
          </div>
          <div className={classes.label}>
            Working hours
          </div>
          <div className={classes.value}>
            {workingHoursInHours} h
          </div>
          <Form.Item name='working_hours'>
            <TimePicker.RangePicker format={'hh:mm'}/>
          </Form.Item>
        </div>
        
      </div>
      <div className={classes.smallFormBlock}>
        <div className={classes.formIconWrapper}>
          <RateIcon/>
        </div>
        <div className={classes.label}>
          Rate
        </div>
        <div className={classes.value}>
          {rateValue}
        </div>
        <Form.Item name='rate'>
          <Rate />
        </Form.Item>
      </div>
    </div>
  )
}

export interface MainInformationTabPropTypes {
  form: FormInstance
  transportName: string
  isCodeOptional?: boolean
  isEditing?: boolean
  itemId?: number
}

interface GeneralInfoBlockPropTypes extends MainInformationTabPropTypes {
  coordinates: string
  handleCoordinatesChange: (coordinates: string) => void
}

export default MainInformationTab
