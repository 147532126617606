import { Button, Modal, Select, Spin, Upload } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { debounce, sortBy } from 'lodash'
import classes from './UploadModal.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetCitiesBySearchQueryThunk, GetCountriesBySearchQueryThunk, selectCityOptions, selectCountryOptions, setCityOptions, setCountryOptions } from '../../../store/locationsReducer'
import { UploadGTFSThunk } from '../../../store/gtfsReducer'
import axios from './../../../helpers/axiosHelper'
import { ReactComponent as UploadIcon } from './../../../img/airportDetailsForm/uploadIcon.svg'
import { dummyRequest, normFile } from '../../../helpers/files_helper'

const UploadModal: React.FC<UploadModalPropTypes> = ({isModalOpen, onClose}) => {
  const dispatch = useAppDispatch()
  const cityOptions = useAppSelector(selectCityOptions)
  const countryOptions = useAppSelector(selectCountryOptions)
  const [submitSource, setSubmitSource] = useState(axios.CancelToken.source())

  interface FormValuesType {
    country_id: number | undefined,
    city_id: number | undefined,
    archive: any
  }

  const formDefaultValues = {
    country_id: undefined,
    city_id: undefined,
    archive: undefined
  }

  if (isModalOpen) {
    window.onbeforeunload = (e) => {
      e = e || window.event
      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = 'Sure?'
      }
      // For Safari
      return 'Sure?'
    }
  } else {
    window.onbeforeunload = () => {}
  }

  const [formValues, setFormValues] = useState<FormValuesType>(formDefaultValues)
  const [isSaving, setIsSaving] = useState(false)
  const [countrySearchValue, setCountrySearchValue] = useState('')
  const [citySearchValue, setCitySearchValue] = useState('')
  const [isCityOptionsLoading,  setIsCityOptionsLoading] = useState(false)
  const [isCountryOptionsLoading,  setIsCountryOptionsLoading] = useState(false)

  const getValidationMessage = () => {
    if (!formValues?.country_id) {
      return 'Please select a country'
    } 
    else if (!formValues?.city_id) {
      return 'Please select a city'
    } 
    else if (!formValues?.archive) {
      return 'Please upload a file'
    }
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if (!!countrySearchValue.length) {
      setIsCountryOptionsLoading(true)
      dispatch(GetCountriesBySearchQueryThunk({searchQuery: countrySearchValue, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsCountryOptionsLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, countrySearchValue])

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if (!!citySearchValue.length) {
      setIsCityOptionsLoading(true)
      dispatch(GetCitiesBySearchQueryThunk({searchQuery: citySearchValue, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsCityOptionsLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, citySearchValue])

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchQuery: string, field: 'city' | 'country') => {
      field === 'city' ? setCitySearchValue(searchQuery) : setCountrySearchValue(searchQuery)
    }, 350), []
  )

  const addFile = async(file:any) => {
    setFormValues({
      ...formValues,
      archive: file
    })
  }

  const closeModal = () => {
    submitSource.cancel()
    dispatch(setCityOptions([]))
    dispatch(setCountryOptions([]))
    setCountrySearchValue('')
    setCitySearchValue('')
    setFormValues(formDefaultValues)
    onClose()
  }

  const onSubmit = () => {
    setIsSaving(true)
    dispatch(UploadGTFSThunk({
      countryId: formValues?.country_id!,
      cityId: formValues?.city_id!,
      file: formValues?.archive!,
      source: submitSource
    })).then((resp) => {
      setIsSaving(false)
      setSubmitSource(axios.CancelToken.source())
      if (!resp.type.includes('rejected')) {
        closeModal()
      }
    })
  }

  return (
    <Modal
      visible={isModalOpen}
      // className={classes.modal}
      footer={false}
      closable={false}
      destroyOnClose
    >
      <div className={classes.title}>
        Upload files
      </div>
      <div className={classes.description}>
        Upload and attach files.
      </div>
      
      <div className={classes.label}>
        Select Country
      </div>
      <div id='country-select'>
        <Select
          placeholder='Select country'
          showSearch
          onSearch={(val) => handleSearchDebounce(val, 'country')}
          filterOption={(input, option) => {
            const countryName = option!.children as unknown as string
            return countryName?.toLowerCase().includes(input?.toLowerCase())
          }}
          onSelect={(val:number) => setFormValues({...formValues, country_id: val})}
          style={{width: '100%'}}
          value={formValues.country_id}
          disabled={isSaving}
          getPopupContainer={() => document.getElementById('country-select')!}
          notFoundContent={isCountryOptionsLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!countrySearchValue.length && !countryOptions.length && 'No results found'}
              {!countrySearchValue.length && !countryOptions.length && 'Start typing country name'}
            </>
          )}
        >
          {sortBy(countryOptions, country => country.name).map(country => (
            <Select.Option value={country.id} key={country.code}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={classes.label}>
        Select City
      </div>
      <div id='city-select'>
        <Select
          placeholder={'Select city'}
          showSearch
          onSelect={(_: any, option: {value: number}) => setFormValues({...formValues, city_id: option.value})}
          onSearch={(val) => handleSearchDebounce(val, 'city')}
          // searchValue={inputValue}
          style={{width: '100%'}}
          filterOption={false}
          labelInValue
          disabled={isSaving}
          getPopupContainer={() => document.getElementById('city-select')!}
          notFoundContent={isCityOptionsLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!citySearchValue.length && !cityOptions.length && 'No results found'}
              {!citySearchValue.length && !cityOptions.length && 'Start typing city name'}
            </>
          )}
        >
          {cityOptions.map((optionGroup) => (
            <Select.OptGroup
              label={optionGroup.name}
              key={optionGroup.id}
            >
              {optionGroup.cities.map(c => (
                <Select.Option value={c.id} key={c.id}>
                  {c.name} {c?.state_name?.length ? '('+c?.state_name+')' : ''} 
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </div>

      <Upload.Dragger
        name='files'
        customRequest={dummyRequest}
        beforeUpload={() => true}
        className={`${classes.uploadDragger}`}
        accept={'.zip,.rar,.7zip'}
        maxCount={10}
        onChange={(e) => normFile(e, addFile)}
        showUploadList={false}
        style={{marginTop: '20px'}}
      >
        {!!formValues?.archive ? (
          <>
            <div>{formValues.archive?.originFileObj?.name}</div>
          </>
        ) : (
          <>
            <UploadIcon />
            <div>
              Click to upload or drag and drop the file
            </div>
          </>
        )}
      </Upload.Dragger>

      <div className={classes.validationMessage}>
        {getValidationMessage()}
      </div>
      <div className={classes.btnArea}>
        <Button onClick={() => closeModal()} disabled={isSaving}>
          Cancel
        </Button>
        <Button
          type='primary'
          disabled={Object.values(formValues).some(value => value === undefined)}
          onClick={onSubmit}
          loading={isSaving}
        >
          Upload file
        </Button>
      </div>
    </Modal>
  )
}

interface UploadModalPropTypes {
  isModalOpen: boolean
  onClose: () => void
}

export default UploadModal
