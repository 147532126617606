import { useEffect, useState } from 'react'
import { Button, Form, Upload } from 'antd'
import { useNavigate  } from 'react-router-dom'
import InputField from '../common/formFields/InputField'
import classes from './Settings.module.css'
import avatarDefault from './../../img/avatarDefault.svg'
import { dummyRequest, getImageUrl } from '../../helpers/files_helper'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { EditAdminDataThunk, EditPasswordThunk, selectUserData } from '../../store/userReducer'
import { tokenDataHelper } from '../../helpers/localStorageHelper'
import dayjs from 'dayjs'
import { refreshToken } from '../../helpers/axiosHelper'

const Settings = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const newPasswordValue = Form.useWatch('new_password', form)
  const adminData = useAppSelector(selectUserData)

  const [avatarPreview, setAvatarPreview] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [deleteAvatar, setIsDeleteAvatar] = useState(false)


  useEffect(() => {
    const {token, token_expiry_date} = tokenDataHelper.getTokenData()
    if (token && dayjs().isAfter(token_expiry_date)) {
      refreshToken(token)
    }
  }, [])

  useEffect(() => {
    !!adminData.photo_url?.length && setAvatarPreview(adminData.photo_url)
  }, [adminData])

  const saveChanges = async(useMainData: any, passwordData: PasswordDataType) => {
    return Promise.all([
      dispatch(EditAdminDataThunk(useMainData)),
      ...(!!passwordData.new_password?.length ? [dispatch(EditPasswordThunk(passwordData))] : [])
    ])
  }
  
  const handleSave = (data: SettingsFormData) => {
    const adminMainData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
    }
    const userMainData = new FormData()

    if (!!data.avatar && typeof data.avatar !== 'string') {
      userMainData.append('photo', data.avatar[0].originFileObj)
    }
    userMainData.append('user_settings', new Blob([JSON.stringify({delete_photo: deleteAvatar}, null, 2)], {type: 'application/json'}))
    userMainData.append('user', new Blob([JSON.stringify(adminMainData, null, 2)], {type: 'application/json'}))

    const passwordData = {
      current_password: data.current_password,
      new_password: data.new_password
    }
   
    setIsLoading(true)
    saveChanges(userMainData, passwordData)
      .then((resp) => {
        setIsLoading(false)
        if (!resp.some((r) => r.type.includes('rejected'))) {
          navigate('/')
        }
      })
  }

  const setPreview = async(file:any) => {
    const imageUrl = await getImageUrl(file)
    setAvatarPreview(imageUrl)
  }

  const normFile = (e: any) => {
    setPreview(e.file)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const removeAvatar = () => {
    setAvatarPreview('')
    form.setFieldsValue({avatar: null})
    setIsDeleteAvatar(true)
  }
  
  return (
    <Form
      name='mainInformation'
      onFinish={handleSave}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
      initialValues={{
        first_name: adminData.first_name,
        last_name: adminData.last_name,
        email: adminData.email,
      }}
    >
      <div className={classes.contentWrapper}>
        <h1>
          Settings
        </h1>
        <div className={classes.form}>
          <div className={classes.personalInfoWrapper}>
            <div>
              <h2>
                Personal information
              </h2>
              <InputField
                name='first_name'
                placeholder='Enter your first name'
                label='First Name'
              />
              <InputField
                name='last_name'
                placeholder='Enter your last name'
                label='Last Name'
              />
              <InputField
                name='email'
                placeholder='Enter your email'
                label='Email'
                rules={[{ type: 'email', message: 'Invalid e-mail!' }]}
              />
            </div>

            <div className={classes.avatarWrapper}>
              <img src={avatarPreview || avatarDefault} alt='avatar'/>
              <Form.Item
                name='avatar'
                valuePropName='fileList'
                getValueFromEvent={normFile}
              >
                <Upload
                  name='avatarUpload'
                  customRequest={dummyRequest}
                  accept={'image/png, image/jpeg'}
                  maxCount={1}
                  showUploadList={false}
                >
                  <div className={classes.changeAvatarBtn}>
                    Change
                  </div>
                </Upload>
              </Form.Item>
              <div className={classes.removeAvatarBtn} onClick={removeAvatar}>
                Remove
              </div>
            </div>
          </div>
          <div className={classes.newPasswordWrapper}>
            <h2>
              Password
            </h2>
            <InputField
              name='current_password'
              placeholder='Enter your current password'
              autoComplete='new-password'
              label='Current password'
              type='password'
              requiredMessage={newPasswordValue?.length ? 'Please enter your current password!' : ''}
            />
            <InputField
              name='new_password'
              placeholder='Enter new password'
              label='New password'
              type='password'
              rules={[{ min: 6, message: 'Password must be at least 6 characters '}]}
            />
            <InputField
              name='confirm_password'
              placeholder='Confirm password'
              label='Confirm password'
              dependencies={['new_password']}
              type='password'
              requiredMessage={newPasswordValue?.length ? 'Please repeat your password!' : ''}
              matchWith={{field: 'new_password', message: 'The two passwords that you entered do not match!'}}
            />
          </div>
        </div>
      </div>

      <div className={classes.buttonsArea}>
        <Button onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button htmlType='submit' type='primary' loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  )
}

interface SettingsFormData {
  avatar: any
  email: string
  first_name: string
  last_name: string
  current_password: string
  new_password: string
  confirm_password: string
}

interface PasswordDataType {
  current_password: string
  new_password: string
}

export default Settings
