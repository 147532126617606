import { FormInstance, InputNumber, Popover, Radio, Select, Spin } from 'antd'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import classes from './ConnectionField.module.css'
import { ConnectionOptions } from '../../Services/ServiceDetailsForm/ServiceDetailsForm'
import SelectNode from './SelectNode/SelectNode'
import { ServiceType } from '../../../types/serviceTypes'
import { InputGoogleAPI } from '../InputGoogleAPI/InputGoogleAPI'
import CombinedCoordinatesInput from '../CombinedCoordinatesInput/CombinedCoordinatesInput'
import {ReactComponent as QuestionMarkIcon} from './../../../img/icons/questionMark.svg'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetCitiesBySearchQueryThunk, GetPlaceDataThunk, GetPlaceNameByCoordinatesThunk, selectAllCountries, selectCityOptions, selectDistanceUnits, setCityOptions } from '../../../store/locationsReducer'
import { AdvertisementEditingType } from '../../../types/advertisingTypes'
import axios from './../../../helpers/axiosHelper'
import { debounce, sortBy } from 'lodash'
import UserLocationConnectionBlock from './UserLocationConnectionBlock/UserLocationConnectionBlock'
import { LocationGoogleApiDataType } from '../../../types/locationTypes'

const emptyCoordinateValues = {
  latitude: undefined,
  longitude: undefined,
  address: undefined,
}
const emptySetOfCoordinateValues = {
  latitude_north: undefined,
  latitude_south: undefined,
  longitude_east: undefined,
  longitude_west: undefined,
}
const emptyCoordinatesWithRadiusValues = {
  latitude: null,
  longitude: null,
  radius: null,
  unit: null,
}

const initialValues = {
  nodes: undefined,
  node_id_list: undefined,
  latitude: undefined,
  longitude: undefined,
  latitude_north: undefined,
  latitude_south: undefined,
  longitude_east: undefined,
  longitude_west: undefined,
  radius_area: undefined,
  is_global: false,
  address: undefined,
}

const pageText = {
  service: {
    connectionBlock1Title: 'Select the option of where to display the service?',
    setOfCoordinatesPopover: 'The service will be connected to this area.',
    userLocationConnectionBlockTitle: 'Select the option for whom the service should be displayed?'
  },
  advertisement: {
    connectionBlock1Title: 'Select the option of according to the location(s) searched by the user:',
    setOfCoordinatesPopover: 'Advertisement will be connected to this area.',
    userLocationConnectionBlockTitle: 'Select the option of according to the user’s location:'
  },
}

const ConnectionField: React.FC<ConnectionFieldPropTypes> = ({
  form,
  setError,
  selectedConnectionOption,
  setSelectedConnectionOption,
  isEditing,
  currentEditingData,
  itemForConnection,
  disabled,
  pagination,
  handleNodeDeselect,
  handleNodeSelect,
  onConnectionTypeChange
}) => {

  useEffect(() => {
    return () => form.setFieldsValue({...initialValues})
  }, [form])

  const handleConnectionTypeChange = (type: number):void => {
    if (handleNodeSelect && (
        (type === ConnectionOptions.Global || type === ConnectionOptions.Coordinates)
        // @ts-ignore
        || (currentEditingData?.advertising_locations?.[0]?.location_type === 'node' && type !== ConnectionOptions.Nodes)
        // @ts-ignore
        || (currentEditingData?.advertising_locations?.[0]?.location_type === 'City' && type !== ConnectionOptions.City)
        // @ts-ignore
        || (currentEditingData?.advertising_locations?.[0]?.location_type === 'Country' && type !== ConnectionOptions.Country)
      )) {
      handleNodeSelect(null)
      form.setFieldsValue({...initialValues})
    }
    onConnectionTypeChange && onConnectionTypeChange()
    form.setFieldsValue({...initialValues, is_global: type === ConnectionOptions.Global})
    setSelectedConnectionOption(type)
    setError('')
  }

  return (
    <>
      <div className={`${classes.blockTitle} ${itemForConnection === 'advertisement' ? classes.advertisement : ''}`}>
        {pageText[itemForConnection].connectionBlock1Title}
      </div>
      <div className={classes.optionsWrapper}>
        <Radio.Group
          value={selectedConnectionOption}
          onChange={(e) => handleConnectionTypeChange(e.target.value)}
          style={{marginBottom: '20px'}}
          disabled={disabled}
        >
          {Object.values(ConnectionOptions).filter(opt => typeof opt === 'string').map((opt, index) => (
            <Radio value={index} key={opt}>
              {opt}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      
      <div className={classes.fieldsWrapper}>
        {selectedConnectionOption === ConnectionOptions.Nodes && 
          <SelectNode
            form={form}
            isEditing={isEditing}
            initialValues={initialValues}
            currentEditingData={currentEditingData}
            disabled={disabled}
            handleNodeDeselect={handleNodeDeselect}
            handleNodeSelect={handleNodeSelect}
          />
        }
        {selectedConnectionOption === ConnectionOptions.Coordinates &&
          <EnterCoordinates
            form={form}
            isEditing={isEditing}
            currentEditingData={currentEditingData}
            itemForConnection={itemForConnection}
            disabled={disabled}
          />
        }
        {selectedConnectionOption === ConnectionOptions.City &&
          <SelectCity
            form={form}
            isEditing={isEditing}
            currentEditingData={currentEditingData}
            disabled={disabled}
            handleNodeDeselect={handleNodeDeselect}
            handleNodeSelect={handleNodeSelect}
          />
        }
        {selectedConnectionOption === ConnectionOptions.Country &&
          <SelectCountry
            form={form}
            isEditing={isEditing}
            currentEditingData={currentEditingData}
            disabled={disabled}
            handleNodeDeselect={handleNodeDeselect}
          />
        }
        {selectedConnectionOption === ConnectionOptions.Global &&
          <div className={classes.globalOptionText}>
            This {itemForConnection} {isEditing ? 'is' : 'will be'} global
          </div>
        }
        {pagination?.totalCount && isEditing
        && (
          // @ts-ignore
          (currentEditingData?.advertising_locations?.[0]?.location_type === 'node' && selectedConnectionOption === ConnectionOptions.Nodes)
          // @ts-ignore
          || (currentEditingData?.advertising_locations?.[0]?.location_type === 'City' && selectedConnectionOption === ConnectionOptions.City)
          // @ts-ignore
          || (currentEditingData?.advertising_locations?.[0]?.location_type === 'Country' && selectedConnectionOption === ConnectionOptions.Country)
        ) &&
          <>
            {pagination?.isLoading ? (
              <div><Spin /></div>
            ) : (
                <div className={classes.paginationWrapper}>
                  <div onClick={() => pagination?.onLoadMore()} className={classes.paginationLoadMore}>
                    See more
                  </div>
                  <div>
                    {pagination?.isLoading ? <Spin /> : `${pagination?.loadedCount}/${pagination.totalCount} options`}
                  </div>
                </div>
            )}
          </>
        }
      </div>
      <UserLocationConnectionBlock
        form={form}
        isEditing={isEditing}
        currentEditingData={currentEditingData}
        blockTitle={pageText[itemForConnection].userLocationConnectionBlockTitle}
        itemForConnection={itemForConnection}
        disabled={disabled}
      />
    </>
  )
}

const EnterCoordinates: React.FC<ConnectionOptionPropTypes> = ({form, isEditing, currentEditingData, itemForConnection, disabled}) => {
  const dispatch = useAppDispatch()
  const distanceUnits = useAppSelector(selectDistanceUnits)

  const getAddressByCoordinates = async(coordinates: [number, number]) => {
    const resp = await dispatch(GetPlaceNameByCoordinatesThunk({lat: coordinates[0], lng: coordinates[1]}))
    return resp.payload as string
  }

  // const [coordinateValues, setCoordinateValues] = useState<any>('')
  const [addressValue, setAddressValue] = useState<any>()
  const [coordinateValueSet, setCoordinateValueSet] = useState<any>(emptySetOfCoordinateValues)
  const [coordinatesWithRadiusValue, setCoordinatesWithRadiusValue] = useState<any>(emptyCoordinatesWithRadiusValues)

  useEffect(() => {
    if (!!isEditing && !!Object.keys(currentEditingData || {})?.length) {
      if (!!currentEditingData?.radius_area && Object.keys(currentEditingData?.radius_area)) {
        setCoordinatesWithRadiusValue(currentEditingData?.radius_area)
      } else if (!!currentEditingData?.latitude_north) {
        setCoordinateValueSet({
          latitude_north: currentEditingData?.latitude_north,
          latitude_south: currentEditingData?.latitude_south,
          longitude_east: currentEditingData?.longitude_east,
          longitude_west: currentEditingData?.longitude_west,
        })
      } else if (!!currentEditingData?.latitude) {
        // setCoordinateValues(`${currentEditingData?.latitude}, ${currentEditingData?.longitude}`)
        currentEditingData?.address
          ? setAddressValue(currentEditingData.address)
          : getAddressByCoordinates([currentEditingData?.latitude, currentEditingData?.longitude!])
            .then(resp => setAddressValue(resp))
      }
    }
    // eslint-disable-next-line
  }, [isEditing, currentEditingData])

  const handleCoordinateValueSetChange = (key: string, value: string) => {
    form.setFieldsValue({...initialValues, ...coordinateValueSet, [key]: value})
    // setCoordinateValues(emptyCoordinateValues)
    setCoordinatesWithRadiusValue(emptyCoordinatesWithRadiusValues)
    setAddressValue(undefined)
    setCoordinateValueSet({...coordinateValueSet, [key]: value})
  }

  const handleAddressChange = async(value: string) => {
    if (value.length) {
      const addressResp = await dispatch(GetPlaceDataThunk({address: value}))
      const addressData = await (addressResp.payload as LocationGoogleApiDataType)        
      form.setFieldsValue({
        ...initialValues,
        latitude: addressData?.coordinates?.lat,
        longitude: addressData?.coordinates?.lng,
        address: value,
        place_id: addressData?.place_id
      })
      // setCoordinateValues(`${addressData?.coordinates?.lat}, ${addressData?.coordinates?.lng}`)
      setAddressValue(value)
    } else {
      form.setFieldsValue({latitude: null, longitude: null})
      setAddressValue(undefined)
      // setCoordinateValues(emptyCoordinateValues)
    }
    setCoordinateValueSet(emptySetOfCoordinateValues)
    setCoordinatesWithRadiusValue(emptyCoordinatesWithRadiusValues)
  }

  const handleRadiusChange = (newValue: {[key: string]: string | undefined}) => {
    form.setFieldsValue({...initialValues, radius_area: {...coordinatesWithRadiusValue, ...newValue}})

    // setCoordinateValues(emptyCoordinateValues)
    setCoordinateValueSet(emptySetOfCoordinateValues)
    setAddressValue(undefined)
    setCoordinatesWithRadiusValue({...coordinatesWithRadiusValue, ...newValue})
  }

  const handleCoordinateWithRadiusChange = (value: string, isValueCompleted: boolean, isValueEmpty: boolean) => {
    if (isValueCompleted) {
      const [latitude, longitude] = value.split(', ')
      handleRadiusChange({latitude: latitude, longitude: longitude})
    } else if (isValueEmpty) {
      handleRadiusChange({latitude: undefined, longitude: undefined})
    }
  }

  return (
    <>
      <div className={classes.optionExplanation}>
        Enter set of coordinates
        <Popover
          content={<div style={{textAlign: 'center', width: '350px'}}>Enter 4 values and the system will build a square area based on those coordinates. { pageText[itemForConnection!].setOfCoordinatesPopover }</div>}
        >
          <QuestionMarkIcon style={{marginLeft: '5px', cursor: 'pointer', width: '10px', position: 'absolute'}}/>
        </Popover>
      </div>
      <div className={classes.doubleInputArea}>
        <div>
          <div className={classes.explanationText}>
            Latitude (North)
          </div>
          <InputNumber
            placeholder='Enter Latitude (North)'
            onChange={(val) => handleCoordinateValueSetChange('latitude_north', val)}
            value={coordinateValueSet.latitude_north}
            style={{width: '100%'}}
            controls={false}
            disabled={disabled}
          />
        </div>
        <div>
          <div className={classes.explanationText}>
            Latitude (South)
          </div>
          <InputNumber
            placeholder='Enter Latitude (South)'
            onChange={(val) => handleCoordinateValueSetChange('latitude_south', val)}
            value={coordinateValueSet.latitude_south}
            style={{width: '100%'}}
            controls={false}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={classes.doubleInputArea}>
        <div>
          <div className={classes.explanationText}>
            Longitude (East)
          </div>
          <InputNumber
            placeholder='Enter Longitude (East)'
            onChange={(val) => handleCoordinateValueSetChange('longitude_east', val)}
            value={coordinateValueSet.longitude_east}
            style={{width: '100%'}}
            controls={false}
            disabled={disabled}
          />
        </div>
        <div>
          <div className={classes.explanationText}>
            Longitude (West)
          </div>
          <InputNumber
            placeholder='Enter Longitude (West)'
            onChange={(val) => handleCoordinateValueSetChange('longitude_west', val)}
            value={coordinateValueSet.longitude_west}
            style={{width: '100%'}}
            controls={false}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={classes.optionExplanation}>
        Or
      </div>
      <div className={classes.optionExplanation}>
        Enter coordinates and radius
      </div>
      <CombinedCoordinatesInput
        onChange={handleCoordinateWithRadiusChange}
        initialValue={coordinatesWithRadiusValue?.latitude ? `${coordinatesWithRadiusValue?.latitude}, ${coordinatesWithRadiusValue?.longitude}` : undefined}
        disabled={disabled}
      />
      {!!form.getFieldValue('radius_area') && !!form.getFieldValue('radius_area')?.latitude && !!form.getFieldValue('radius_area')?.longitude &&
        <div className={classes.additionalInfoWrapper} style={{marginBottom: '10px'}}>
          <div className={classes.additionalInfo}>Latitude: {form.getFieldValue('radius_area')?.latitude}</div>
          <div className={classes.additionalInfo}>Longitude: {form.getFieldValue('radius_area')?.longitude}</div>
        </div>
      }
      <div className={classes.doubleInputArea}>
        <div>
          <div className={classes.explanationText}>
            Radius
          </div>
          <InputNumber
            placeholder='Enter Radius'
            onChange={(val) => handleRadiusChange({radius: val})}
            value={coordinatesWithRadiusValue.radius}
            style={{width: '100%'}}
            controls={false}
            disabled={disabled}
          />
        </div>
        <div>
          <div className={classes.explanationText}>
            Units
          </div>
          <Select
            style={{width: '100%'}}
            placeholder='Select Units'
            value={coordinatesWithRadiusValue.unit}
            onChange={(val) => handleRadiusChange({unit: val})}
            disabled={disabled}
          >
            {distanceUnits.map(unit => <Select.Option key={unit} value={unit}>{unit}</Select.Option>)}
          </Select>
        </div>
      </div>

      <div className={classes.optionExplanation}>
        Or
      </div>
      <div className={classes.optionExplanation}>
        Enter address
      </div>
      <div>
        <div className={classes.explanationText}>
          Address
        </div>
          <InputGoogleAPI
            placeholder='Enter Address'
            onSelect={(value) =>  handleAddressChange(value)}
            value={addressValue}
            disabled={disabled}
            style={{width: '100%'}}
        />
      </div>
      {/* <CombinedCoordinatesInput
        onChange={handleCoordinateValueChange}
        initialValue={coordinateValues}
        disabled={true}
      /> */}
      {!!form.getFieldValue('latitude') && !!form.getFieldValue('longitude') &&
        <div className={classes.additionalInfoWrapper}>
          <div className={classes.additionalInfo}>Latitude: {form.getFieldValue('latitude')}</div>
          <div className={classes.additionalInfo}>Longitude: {form.getFieldValue('longitude')}</div>
        </div>
      }
    </>
  )
}

const SelectCity: React.FC<ConnectionOptionPropTypes> = ({form, isEditing, currentEditingData, disabled, handleNodeDeselect, handleNodeSelect}) => {
  const dispatch = useAppDispatch()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const cityOptions = useAppSelector(selectCityOptions)

  const [searchQuery,  setSearchQuery] = useState('')
  const [selectedValues, setSelectedValues] = useState<{label: string, value: number}[]>([])
  const [isLoading,  setIsLoading] = useState(false)

  useEffect(() => {
    if (!!searchQuery.length) {
      setIsLoading(true)
      dispatch(GetCitiesBySearchQueryThunk({searchQuery, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, searchQuery])

  useEffect(() => {
    if (!!isEditing && !!currentEditingData?.nodes?.length && currentEditingData?.nodes?.[0]?.label?.toLowerCase() === 'city') {
      setSelectedValues(currentEditingData?.nodes.map(node => ({label: node.name, value: node.id})))
      form.setFieldsValue({
        nodes: currentEditingData?.nodes?.map(n => n.id),
        node_id_list: currentEditingData?.nodes?.map(n => n.id)
      })
    } else {
      setSelectedValues([])
    }
  }, [currentEditingData, isEditing, form])

  const handleSelect = (selectedValue: {value: number, label: string}) => {
    handleNodeSelect && handleNodeSelect(selectedValue.value, (nodeTypes?: string[]) => nodeTypes!.some(t => t !== 'City'))
    const updatedValues = [...selectedValues, selectedValue]
    form.setFieldsValue({...initialValues, node_id_list: updatedValues.map(val => val.value)})
    setSelectedValues(updatedValues)
    setSearchQuery('')
    dispatch(setCityOptions([]))
  }

  const handleDeselect = ({value}: {value: number}) => {
    !!handleNodeDeselect && handleNodeDeselect(value)
    const updatedValues = selectedValues.filter(val => val.value !== value)
    form.setFieldsValue({...initialValues, node_id_list: updatedValues.map(val => val.value)})
    setSelectedValues(updatedValues)
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchQuery: string) => {
      setSearchQuery(searchQuery)
    }, 350),
    []
  )

  return (
    <>
      <div className={classes.explanationText}>
        Select City (You can select one or many cities)
      </div>
      <Select
        placeholder='Please select city'
        showSearch
        mode='multiple'
        onSelect={(_: any, option: {value: number, children: string}) => handleSelect({value: option.value, label: option.children})}
        onDeselect={handleDeselect}
        onSearch={handleSearchDebounce}
        style={{width: '65%'}}
        filterOption={false}
        value={selectedValues}
        labelInValue
        disabled={disabled}
        notFoundContent={isLoading ? (
          <Spin size='small' />
        ) : (
          <>
            {!!searchQuery.length && !cityOptions.length && 'No results found'}
            {!searchQuery.length && !cityOptions.length && 'Start typing city name'}
          </>
        )}
      >
        {cityOptions.map((optionGroup) => (
           <Select.OptGroup
            label={optionGroup.name}
            key={optionGroup.id}
          >
            {optionGroup.cities.map(c => (
              <Select.Option value={c.id} key={c.id}>
                {c.name} {c?.state_name?.length ? '('+c?.state_name+')' : ''} 
              </Select.Option>
            ))}
         </Select.OptGroup>
        ))}
      </Select>
    </>
  )
}

const SelectCountry: React.FC<ConnectionOptionPropTypes> = ({form, isEditing, currentEditingData, disabled, handleNodeSelect}) => {
  const countries = useAppSelector(selectAllCountries)
  const [selectedValue, setSelectedValue] = useState<number>()

  useEffect(() => {
    if (!!isEditing && !!currentEditingData?.nodes?.length && currentEditingData?.nodes?.[0]?.label?.toLowerCase() === 'country') {
      setSelectedValue(currentEditingData?.nodes?.[0]?.id)
    } else {
      setSelectedValue(undefined)
    }
  }, [currentEditingData, isEditing, form])

  const handleSelect = (id: number) => {
    handleNodeSelect && handleNodeSelect(id, () => true)
    setSelectedValue(id)
    form.setFieldsValue({...initialValues, node_id_list: [id]})
  }

  return (
    <>
      <div className={classes.explanationText}>
        Select Country
      </div>
      <Select
        placeholder='Please select country'
        showSearch
        filterOption={(input, option) => {
          const countryName = option!.children as unknown as string
          return countryName?.toLowerCase().includes(input?.toLowerCase())
        }}
        onSelect={handleSelect}
        style={{width: '65%'}}
        value={selectedValue}
        disabled={disabled}
        notFoundContent={countries?.length ? 'No results found' : <Spin/>}
      >
        {sortBy(countries, country => country.name).map(country => (
          <Select.Option value={country.id} key={country.code}>
            {country.name}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

interface ConnectionFieldPropTypes {
  selectedConnectionOption: number
  setSelectedConnectionOption: (options: ConnectionOptions) => void | Dispatch<SetStateAction<ConnectionOptions>> 
  form: FormInstance
  isEditing: boolean
  setError: Dispatch<SetStateAction<string>>
  currentEditingData: ServiceType | AdvertisementEditingType | null
  itemForConnection: 'service' | 'advertisement',
  disabled?: boolean
  pagination?: {
    loadedCount: number
    totalCount: number
    onLoadMore: () => void
    isLoading: boolean
  }
  handleNodeDeselect?: (nodeId: number) => void
  handleNodeSelect?: (nodeId: number | null, removeCopiedNodes?: (nodeTypes?: string[]) => boolean) => void
  onConnectionTypeChange?: () => void
} 

interface ConnectionOptionPropTypes {
  form: FormInstance
  isEditing: boolean
  currentEditingData: ServiceType | AdvertisementEditingType | null
  itemForConnection?: 'service' | 'advertisement',
  disabled?: boolean
  handleNodeDeselect?: (nodeId: number) => void
  handleNodeSelect?: (nodeId: number | null, removeCopiedNodes?: (nodeTypes?: string[]) => boolean) => void
}

export default ConnectionField
