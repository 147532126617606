import axios from './../helpers/axiosHelper'
import axiosForNoTokenRequests from 'axios'
import { AirportApiType, BusStationApiType, LocationApiType, PortApiType, ServiceApiType, RailwayStationApiType, UserApiType, TerminalApiType, CruiseApiType, TrackingApiType, AdvertisingApiType, CruiseLineApiType, BlogApiType, GTFSApiType, EventApiType, AirlineApiType, WidgetApiType, FaqApiType, RentalApiType, UsersApiType } from '../types/apiTypes'

export const userAPI: UserApiType = {
  signIn(loginData) {
    return axios.post('/login', loginData)
  },
  sendEmailForNewPassword(email) {
    return axios.post('/login/password', {email})
  },
  setPassword(passwordData) {
    return axios.post('/login/reset-password', passwordData)
  },
  editAdminData(adminData) {
    return axios.put('/users', adminData)
  },
  editAdminPassword(passwordData) {
    return axios.put('/users/password', passwordData)
  }
}

export const airportAPI: AirportApiType = {
  getAllAirports({pagination ,search_filter, name, search_param, is_triple_digit}, source) {
    return axios.post(
      `/airports/search${'?search_filter='+search_filter}${!!name?.length && !!search_param?.length ? '&search_param=' + search_param: ''}${!!name?.length ? '&search_value='+name : ''}${'&is_triple_digit=' + is_triple_digit}`,
      pagination,
      { cancelToken: source.token}
    )
  },
  getAirportById(airportId) {
    return axios.get(`/airports/${airportId}`)
  },
  createAirport(formData) {
    return axios.post('/airports', formData)
  },
  editAirport(formData, airportId) {
    return axios.put(`/airports/${airportId}`, formData)
  },
  deleteAirport(airportId: number) {
    return axios.delete(`/airports/${airportId}`)
  }
}

export const portAPI: PortApiType = {
  getAllPorts({pagination, search_filter, name, search_param, port_type, is_cruise, is_unk}, source) {
    return axios.post(
      `/ports/search${'?search_filter='+search_filter+'&'}${!!name?.length && !!search_param?.length ? 'search_param=' + search_param + '&' : ''}${!!name?.length ? 'search_value='+name + '&' : ''}${!!port_type ? 'port_type='+port_type + '&' : ''}${is_cruise !== undefined && is_cruise !== null ? 'is_cruise='+is_cruise + '&' : ''}${!!is_unk ? 'is_unk=true' : ''}`,
      pagination,
      { cancelToken: source.token}
    )
  },
  getPortById(portId) {
    return axios.get(`/ports/${portId}`)
  },
  createPort(formData) {
    return axios.post('/ports', formData)
  },
  editPort(formData, portId) {
    return axios.put(`/ports/${portId}`, formData)
  },
  deletePort(portId: number) {
    return axios.delete(`/ports/${portId}`)
  }
}

export const locationAPI: LocationApiType = {
  getAllCountries() {
    return axios.get('/countries')
  },
  getCitiesByCountry(source, countryCode, cityName) {
    return axios.get(`/countries/codes/${countryCode}/cities${cityName?.length ? '?city_name=' + cityName : ''}`, { cancelToken: source.token})
  },
  getCitiesBySearchQuery(cityName, source) {
    return axios.get(`/countries/cities?city-name=${cityName}`, { cancelToken: source.token})
  },
  getDistanceUnits() {
    return axios.get('/utils/distance-unit')
  },
  createCity(cityData, countryId) {
    return axios.post(`/cities${countryId ? '?country_id=' + countryId : ''}`, cityData)
  },
  editCity(cityData, cityId, countryId) {
    return axios.put(`/cities/${cityId}${countryId ? '?country_id=' + countryId : ''}`, cityData)
  },
  createCountry(countryData) {
    return axios.post(`/countries`, countryData)
  },
  editCountry(countryData, countryId) {
    return axios.put(`/countries/${countryId}`, countryData)
  },
  getCountriesWithPagination({pagination, name}, source) {
    return axios.post(`/countries/search${!!name?.length ? '?name='+name : ''}`, pagination || {size: 100, page: 1}, { cancelToken: source.token})
  },
  getCitiesWithPagination({pagination, name, state_name, country_id}, source) {
    const requestUrl = `/cities/search${!!name?.length ? '?city_name='+name+'&' : '?'}${!!country_id ? 'country_id='+country_id+'&' : '&'}${!!state_name?.length ? 'state_name='+state_name+'&' : '&'}`
    return axios.post(requestUrl, pagination, { cancelToken: source.token})
  },
  getCityById(cityId) {
    return axios.get(`/cities/${cityId}`)
  },
  getCountryById(countryId) {
    return axios.get(`/countries/${countryId}`)
  },
  disableCountriesForCountry(countryId, countryIds) {
    return axios.post(`/countries/block/${countryId}`, {country_id_list: countryIds})
  },
  getPlacesAutocomplete(locationSearch, type) {
    return axios.get(`/places/autocomplete?address=${locationSearch}${type ? '&type=' + type : ''}`)
  },
  getPlaceData(data) {
    return axios.get(
      `/places/info?${data?.address ? 'address=' + data?.address : ''}${data?.placeId ? '&place_id=' + data?.placeId : ''}`,
    ) 
  },
  getPlaceNameByCoordinates(coordinates) {
    return axios.get(`/places/location-name?latitude=${coordinates.lat}&longitude=${coordinates.lng}`)
  }
}

export const serviceAPI: ServiceApiType = {
  getAllServices({pagination, name}, source) {
    return axios.post(
      `/services/search${!!name?.length ? '?name='+name : ''}`,
      pagination,
      { cancelToken: source.token}
    )
  },
  getServiceById(serviceId) {
    return axios.get(`/services/${serviceId}`)
  },
  createService(formData) {
    return axios.post('/services', formData)
  },
  editService(formData, serviceId) {
    return axios.put(`/services/${serviceId}`, formData)
  },
  deleteService(serviceId) {
    return axios.delete(`/services/${serviceId}`)
  },
  getAllServiceCategories() {
    return axios.get(`/service-categories`)
  },
  createServiceCategory(category) {
    return axios.post(`/service-categories`, category)
  },
  editServiceCategory(category, categoryId) {
    return axios.put(`/service-categories/${categoryId}`, category)
  },
  getServiceCategorySearchTypes() {
    return axios.get(`/category-search-types`)
  },
  getNodesList(searchQuery, source) {
    return axios.get(`/nodes/without-city?name=${searchQuery}`, { cancelToken: source.token})
  },
  getNodesByCode(code, source) {
    return axios.get(`/nodes/code?code=${code}`, { cancelToken: source.token})
  }
}

export const busStationAPI: BusStationApiType = {
  getAllBusStations({pagination ,search_filter, name, search_param}, source) {
    return axios.post(
      `/bus-stations/search${'?search_filter='+search_filter+'&'}${!!name?.length && !!search_param?.length ? 'search_param=' + search_param + '&' : ''}${!!name?.length ? 'search_value='+name : ''}`,
      pagination,
      { cancelToken: source.token}
    )
  },
  getBusStationById(busStationId) {
    return axios.get(`/bus-stations/${busStationId}`)
  },
  createBusStation(formData) {
    return axios.post('/bus-stations', formData)
  },
  editBusStation(formData, busStationId) {
    return axios.put(`/bus-stations/${busStationId}`, formData)
  },
  deleteBusStation(busStationId: number) {
    return axios.delete(`/bus-stations/${busStationId}`)
  }
}

export const railwayStationAPI: RailwayStationApiType = {
  getAllRailwayStations({pagination ,search_filter, name, search_param}, source) {
    return axios.post(
      `/railway-stations/search${'?search_filter='+search_filter+'&'}${!!name?.length && !!search_param?.length ? 'search_param=' + search_param + '&' : ''}${!!name?.length ? 'search_value='+name : ''}`,
      pagination,
      { cancelToken: source.token}
    )
  },
  getRailwayStationById(railwayStationId) {
    return axios.get(`/railway-stations/${railwayStationId}`)
  },
  createRailwayStation(formData) {
    return axios.post('/railway-stations', formData)
  },
  editRailwayStation(formData, railwayStationId) {
    return axios.put(`/railway-stations/${railwayStationId}`, formData)
  },
  deleteRailwayStation(railwayStationId: number) {
    return axios.delete(`/railway-stations/${railwayStationId}`)
  }
}

export const terminalAPI: TerminalApiType = {
  getAllTerminals({pagination, search_filter, search_value, search_param, node_type}, source) {
    return axios.post(
      `/terminals/search${'?search_filter='+search_filter+'&'}${!!search_value?.length && !!search_param?.length ? 'search_param=' + search_param + '&' : ''}${!!node_type ? 'node_type=' + node_type + '&' : ''}${!!search_value?.length ? 'search_value='+search_value : ''}`,
      pagination,
      { cancelToken: source.token}
    )
  },
  getTerminalById(terminalId) {
    return axios.get(`/terminals/${terminalId}`)
  },
  createTerminal(formData) {
    return axios.post('/terminals', formData)
  },
  editTerminal(formData, terminalId) {
    return axios.put(`/terminals/${terminalId}`, formData)
  },
  deleteTerminal(terminalId) {
    return axios.delete(`/terminals/${terminalId}`)
  },
  getNodeOptions(name, type, source) {
    return axios.get(`/nodes/search?name=${name}&node_type=${type}`, { cancelToken: source.token})
  }
}

export const cruiseAPI: CruiseApiType = {
  getAllCruises(cruiseName, source){
    return axiosForNoTokenRequests.get(
      window.env.API_USER_DOMAIN_ADDR + `cruises${cruiseName ? '?cruise_name='+cruiseName+'&' : ''}without_routes=true`,
      {
        cancelToken: source.token,
        transformRequest: [(data, headers) => {
          delete headers?.Authorization;
          return data 
       }]
      }
    )
  },
  getCruiseList({cruise_line, name, search_param, min_port_distance, pagination}, source) {
    return axios.post(
      `/cruises/search?${cruise_line ? 'cruise_line='+cruise_line+'&' : ''}${!!name?.length && !!search_param?.length ? 'search_param=' + search_param + '&' : ''}${!!name?.length ? 'search_value=' + name + '&' : ''}${!!min_port_distance ? 'min_port_distance=' + min_port_distance + '&' : ''}`,
      pagination,
      { cancelToken: source.token }
    )
  },
  getCruiseById(id, startDate) {
    return axios.get(`/cruises/${id}${!!startDate?.length ? '?start-date='+startDate : ''}`)
  },
  createCruise(data) {
    return axios.post(`/cruises`, data)
  },
  editCruise(data) {
    return axios.put(`/cruises/${data.id}`, data)
  },
  deleteCruise(id) {
    return axios.delete(`/cruises/${id}`)
  },
  updateCruiseRoutePorts(data) {
    return axios.put(`/cruise-routes/port`, data)
  },
  addPortToCruiseRoute(data) {
    return axios.post(`/cruise-routes/port`, data)
  },
  getCruiseRoutesByLocode(portId) {
    return axios.get(`/cruise-routes/distinct?port_id=${portId}`)
  },
  switchCruiseRoutesPort(data) {
    return axios.post(`/cruise-routes/switch`, data)
  },
  getCruiseByLineAndDate(lineId, data, source) {
    return axios.post(
      `/cruises/cruise-lines/${lineId}`,
      data,
      { 
        ...(source ? {cancelToken: source.token} : {})
      }
    )
  },
  getCruiseAvailableDates(data) {
    return axios.post(`/cruises/start-date-locations`, data)
  },
}

export const cruiseLineAPI: CruiseLineApiType = {
  getCruiseLineList(fetchParams, source) {
    return axios.post(
      `/cruise-lines/search${!!fetchParams.name?.length ? '?search_value=' + fetchParams.name + '&search_param=NAME' : ''}`,
      fetchParams.pagination,
      { cancelToken: source.token }
    )
  },
  getCruiseLineById(id) {
    return axios.get(`/cruise-lines/${id}`)
  },
  createCruiseLine(data) {
    return axios.post(`/cruise-lines`, data)
  },
  editCruiseLine(data) {
    return axios.put(`/cruise-lines/${data.id}`, data)
  },
  deleteCruiseLine(id) {
    return axios.delete(`/cruise-lines/${id}`)
  },
  getCruiseLineTypes() {
    return axios.get(`/cruise-line-types`)
  }
}

export const trackingAPI: TrackingApiType = {
  getAllTrackings(fetchParams, source) {
    return axios.post(
      `/trackings`,
      fetchParams, 
      { cancelToken: source.token }
    )
  },
}

export const advertisingAPI: AdvertisingApiType = {
  getAdvertisementsList(fetchParams, source) {
    return axios.post(
      `/advertising/search?search_value=${fetchParams.search_value}`,
      fetchParams.pagination,
      { cancelToken: source.token }
    )
  },
  getAdvertisementById(id, withLocations, source) {
    return axios.get(`/advertising/${id}?with_locations=${withLocations}`, source ? { cancelToken: source.token} : {})
  },
  createAdvertisement(data) {
    return axios.post(`/advertising`, data)
  },
  editAdvertisement(id, data) {
    return axios.put(`/advertising/${id}`, data)
  },
  deleteAdvertisement(id) {
    return axios.delete(`/advertising/${id}`)
  },
  checkOccupiedSlots(data) {
    return axios.post(`/advertising/check-occupied-slots`, data)
  },
  getAdvertisementTargetData(id, pagination, source) {
    return axios.post(`/advertising/${id}/advertising-locations`, pagination, source ? { cancelToken: source.token} : {})
  },
  getSpecialOfferTypes() {
    return axios.get(`/special-offer-types`)
  },
}

export const blogAPI: BlogApiType = {
  getAllBlogEntries(fetchParams, source) {
    return axios.post(
      `/blog-entries/filter`,
      fetchParams,
      { cancelToken: source.token }
    )
  },
  getBlogEntryById(blogEntryId) {
    return axios.get(`/blog-entries/${blogEntryId}`)
  },
  createBlogEntry(data) {
    return axios.post(`/blog-entries`, data)
  },
  editBlogEntry(data, blogEntryId) {
    return axios.put(`/blog-entries/${blogEntryId}`, data)
  },
  deleteBlogEntry(blogEntryId) {
    return axios.delete(`/blog-entries/${blogEntryId}`)
  },
  uploadBlogImage(image) {
    return axios.post(`/blog-images/upload`, image)
  },
  addImagesToBlog(imageData) {
    return axios.post(`/blog-images/list`, imageData)
  },
  deleteImageList(idList) {
    return axios.delete(
      `/blog-images/list`,
      {
        data: {image_ids: idList}
      }
    )
  },
  getAboutPage() {
    return axios.post(`/page-sections/filter`, {type: 'ABOUT'})
  },
  createAboutPage(data) {
    return axios.post(`/page-sections`, data)
  },
  updateAboutPage(parentSectionId, data) {
    return axios.put(`/page-sections/${parentSectionId}`, data)
  },
}

export const GtfsAPI: GTFSApiType = {
  getGTFSList(fetchParams, source) {
    return axios.post(`/gtfs/file-info`, fetchParams, { cancelToken: source?.token })
  },
  deleteGTFSFile(feedInfoId) {
    return axios.delete(`/gtfs/file-info/${feedInfoId}`)
  },
  uploadGTFSFile(countryId, cityId, file, source) {
    return axios.post(`/gtfs/upload-zip?country_id=${countryId}&city_id=${cityId}`, file, { cancelToken: source?.token })
  },
  getGTFSLogList(fileId, fetchParams, source) {
    return axios.post(`/gtfs/file-info/${fileId}/logs`, fetchParams, { cancelToken: source?.token })
  }
}

export const eventAPI: EventApiType = {
  getEventList(fetchParams, source) {
    return axios.post(`/events`, fetchParams, { cancelToken: source?.token })
  },
  getEventByCode(code) {
    return axios.get(`/events/${code}`)
  },
  editEvent(code, eventData) {
    return axios.put(`/events/${code}`, eventData)
  },
  deleteEvent(code) {
    return axios.delete(`/events/${code}`)
  },
}

export const airlineAPI: AirlineApiType = {
  getAllAirlines({pagination, name}, source) {
    return axios.post(
      `/airlines/search${'?name='+ name || ''}`,
      pagination,
      { cancelToken: source.token}
    )
  },
  getAirlineById(airlineId) {
    return axios.get(`/airlines/${airlineId}`)
  },
  createAirline(formData) {
    return axios.post('/airlines', formData)
  },
  editAirline(formData, airlineId) {
    return axios.put(`/airlines/${airlineId}`, formData)
  },
  deleteAirline(airlineId) {
    return axios.delete(`/airlines/${airlineId}`)
  },
  getAllianceList() {
    return axios.get(`/alliances`)
  }
}

export const widgetAPI: WidgetApiType = {
  getWidgetList(fetchParams, searchValue, source) {
    return axios.post(`/widgets/filter${searchValue ? '?search_query=' + searchValue : ''}`, fetchParams, { cancelToken: source?.token })
  },
  getWidgetByCode(code) {
    return axios.get(`/widgets/${code}`)
  },
  deleteWidget(id) {
    return axios.delete(`/widgets/${id}`)
  }
}

export const faqAPI: FaqApiType = {
  getFaqList(searchValue, source) {
    return axios.post(`/page-sections/filter`, {type: 'FAQ', search_value: searchValue}, { cancelToken: source.token })
  },
  getFaqEntryById(id) {
    return axios.get(`/page-sections/${id}`)
  },
  createFaqEntry(data) {
    return axios.post(`/page-sections`, data)
  },
  editFaqEntry(entryId, data) {
    return axios.put(`/page-sections/${entryId}`, data)
  },
  deleteFaqEntry(entryId) {
    return axios.delete(`/page-sections/${entryId}`)
  },
}


export const rentalAPI: RentalApiType = {
  getRentalList(fetchParams, source) {
    return axios.post(`/short-term-rentals/search`, fetchParams, { cancelToken: source?.token })
  },
  getRentalByCode(rentalCode) {
    return axios.get(`/short-term-rentals/${rentalCode}`)
  },
  editRental(rentalCode, data) {
    return axios.put(`/short-term-rentals/${rentalCode}`, data)
  },
  deleteRental(rentalCode) {
    return axios.delete(`/short-term-rentals/${rentalCode}`)
  },
}

export const usersAPI: UsersApiType = {
  getUserList(searchValue, fetchParams, source) {
    return axios.post(`/users/filters${searchValue?.length ? '?search_value=' + searchValue : ''}`, fetchParams, { cancelToken: source?.token })
  }
}
