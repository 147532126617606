import { Button, Checkbox, DatePicker, Form, FormInstance, Input, Radio, Select, Spin, TimePicker } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './EventDetailsPage.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { EditEventThunk, GetEventByCodeThunk, selectCurrentEvent, setCruiseLineOptions, setCurrentEvent } from '../../../store/eventReducer'
import axios from './../../../helpers/axiosHelper'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { EventKindType } from '../../../types/eventTypes'
import CruiseEventFields from './CruiseEventFields'
import { InputGoogleAPI } from '../../common/InputGoogleAPI/InputGoogleAPI'
import ImagesField from './ImagesField'
import { uniqBy } from 'lodash'
import { GetPlaceDataThunk } from '../../../store/locationsReducer'
import { LocationGoogleApiDataType } from '../../../types/locationTypes'

dayjs.extend(utc)

const EventDetailsPage = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentEvent = useAppSelector(selectCurrentEvent)
  const [form] = Form.useForm()

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [selectedEventKind, setSelectedEventKind] = useState<'Cruise' | 'Location'>()
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    // pathname example: /events/edit/111
    const code = +location.pathname.split('/edit/')[1]

    setIsDataLoading(true)
    dispatch(GetEventByCodeThunk(code))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    if (!!currentEvent?.id && !selectedEventKind) {
      setSelectedEventKind(currentEvent?.event_kind)
    }
    if (currentEvent?.event_cruise?.cruise_line_id) {
      // @ts-ignore
      dispatch(setCruiseLineOptions([{
        id: currentEvent?.event_cruise?.cruise_line_id,
        name: currentEvent?.event_cruise?.cruise_line_name
      }]))
    }
    // eslint-disable-next-line
  }, [dispatch, currentEvent])

  useEffect(() => {
    return () => {
      dispatch(setCruiseLineOptions(null))
      dispatch(setCurrentEvent(null))
    }
  }, [dispatch])

  const getBaseUrl = () => {
    if (window.location.origin?.includes('scopia-admin-dev.esverito.com')) {
      return 'https://scopia-user-dev.esverito.com'
    } else if (window.location.origin?.includes('scopia-admin-test.esverito.pl')) {
      return 'https://scopia-user-test.esverito.com'
    } else {
      return 'https://www.relavanti.com'
    }
  }

  const getLocationDetails = async(value: string) => {
    const addressResp = await dispatch(GetPlaceDataThunk({address: value}))
    const addressData = await (addressResp.payload as LocationGoogleApiDataType)
    return {
      country_code: addressData?.country_code|| '',
      country_name: addressData?.country_name || '',
      city_name: addressData?.city_name || '',
      coordinates: {
        latitude: addressData?.coordinates?.lat,
        longitude: addressData?.coordinates?.lng
      }
    }
  }

  const onSubmit = async() => {
    const data = form.getFieldsValue(true)
    const startDate = data.dates[0].set('hours',dayjs.utc(data.time[0])?.get('hours')).set('minute',dayjs.utc(data.time[0])?.get('minute'))
    const endDate = data.dates[1].set('hours',dayjs.utc(data.time[1])?.get('hours')).set('minute',dayjs.utc(data.time[1])?.get('minute'))
    const eventData = {
      delete_photos: data?.delete_photos || [],
      add_photos: uniqBy(data?.add_photos, 'uid')  || [],
      event: {
        name: data.event.name,
        description: data.event.description,
        start_date: startDate.utc()?.format('YYYY-MM-DDTHH:mm:ss'),
        end_date: endDate.utc()?.format('YYYY-MM-DDTHH:mm:ss'),
        event_type: data.event.event_type,
        event_kind: data.event.event_kind,
        terms_accepted: data.event.terms_accepted,
        ...data.event.event_kind === 'Location' ? {
          event_location: {
            name: data.locationName,
            ...await getLocationDetails(data.locationName)
          }
        } : {
          event_cruise: {
            cruise_line_id: data.cruiseLine,
            cruise_line_name: data.cruiseLineName,
            cruise_name: data.cruiseName,
            cruise_start_date: data.cruiseDate?.utc()?.format('YYYY-MM-DD 00:00:00'),
            departure_location: data.cruiseDeparture || undefined
          },
        },
      }
    }
    setIsSaving(true)
    dispatch(EditEventThunk({ code: +currentEvent?.code!, eventData }))
      .then((resp) => {
        setIsSaving(false)
        if(!resp?.type.includes('rejected')) {
          navigate('/events')
        }
      })
  }

  if (isDataLoading || !currentEvent?.id) {
    return <Spin style={{width: '100%'}}/>
  }

  return (
    <>
      <div>
        <Breadcrumbs />
        <h1>
          {currentEvent?.name}
        </h1>
      </div>

      <Form
        initialValues={{
          event: {
            name: currentEvent?.name,
            description: currentEvent?.description,
            event_type: currentEvent?.event_type,
            event_kind: currentEvent?.event_kind,
            terms_accepted: !!currentEvent?.terms_accepted,
          },
          dates: [dayjs.utc(currentEvent?.start_date), dayjs.utc(currentEvent?.end_date)],
          time: [dayjs.utc(currentEvent?.start_date), dayjs.utc(currentEvent?.end_date)],
          locationName: currentEvent?.event_location?.name,
          cruiseLine: currentEvent?.event_cruise?.cruise_line_id,
          cruiseLineName: currentEvent?.event_cruise?.cruise_line_name,
          cruiseName: currentEvent?.event_cruise?.cruise_name,
          cruiseDate: currentEvent?.event_kind === 'Cruise' ? dayjs(currentEvent?.event_cruise?.cruise_start_date) : undefined,
          cruiseDeparture: currentEvent?.event_cruise?.departure_location
        }}
        onFinish={onSubmit}
        form={form}
      >
        <div className={classes.mainInfoArea}>
          <div className={classes.formBlock}>
            <div className={classes.field}>
              <div className={classes.label}>Name Event</div>
              <Form.Item
                name={['event', 'name']}
                rules={[{required: true, message:'This field is required'}]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>Format</div>
              <Form.Item
                name={['event', 'event_kind']}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Select
                  options={[
                    {label: 'Location', value: 'Location'},
                    {label: 'Cruise', value: 'Cruise'}
                  ]}
                  onChange={(val) => setSelectedEventKind(val)}
                />
              </Form.Item>
            </div>

            <EventKingFields eventKind={selectedEventKind} form={form}/>

            <div className={classes.field}>
              <div className={classes.label}>Dates</div>
              <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px'}}>
                <Form.Item name='dates'>
                  <DatePicker.RangePicker
                    allowClear={false}
                    style={{width: '100%'}}
                  />
                </Form.Item>
            
                <Form.Item name='time'>
                  <TimePicker.RangePicker
                    format={'h:mm A'} 
                    allowClear={false}
                    use12Hours
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>Event Type</div>
              <Form.Item
                name={['event', 'event_type']}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Radio.Group buttonStyle='solid' optionType="button" size='large'>
                  <Radio value='Private'> Private </Radio>
                  <Radio value='Corporate'> Semi-Private </Radio>
                  <Radio value='Public'> Public </Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>Terms and Conditions</div>
              <div className={classes.fieldValue}>
                <div>
                  <Form.Item
                    name={['event', 'terms_accepted']}
                    rules={[{ required: true, message: 'This field is required' }]}
                    valuePropName='checked'
                  >
                    <Checkbox disabled> 
                      <span style={{marginLeft: '8px'}}>
                        {currentEvent?.terms_accepted ? 'Terms and Conditions are accepted' : 'Terms and Conditions are not accepted'}
                      </span>
                    </Checkbox>
                  </Form.Item>
                </div>
                {currentEvent?.sign_date &&
                  <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                    <div style={{fontSize: '12px', color: 'rgba(0,0,0,.65)'}}>
                      User IP: {currentEvent?.user_ip}
                    </div>
                    <div style={{fontSize: '12px', color: 'rgba(0,0,0,.65)', marginLeft: '40px'}}>
                      Date: {dayjs(currentEvent?.sign_date).format('DD.MM.YYYY HH:mm')}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className={classes.formBlock}>
            <div className={classes.field}>
              <div className={classes.label}>User name</div>
              <div>
                {currentEvent?.user_name}
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>User email</div>
              <div>
                {currentEvent?.user_email}
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>Event Code</div>
              <div>
                {currentEvent?.code}
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>Event Link</div>
              <div>
                {getBaseUrl() + '/events/details/' + currentEvent?.code}
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.label}>About</div>
              <Form.Item
                name={['event', 'description']}
                // rules={[{required: true, message:'This field is required'}]}
              >
                <Input.TextArea rows={3} placeholder='Enter event description'/>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>

      <ImagesField form={form} />

      <div className={classes.buttons}>
        <Link to='/events'>
          <Button>
            Cancel
          </Button>
        </Link>
        <Button
          type='primary'
          onClick={() => form.submit()}
          loading={isSaving}
        >
          Save
        </Button>
      </div>
    </>
  )
}

const EventKingFields:React.FC<{eventKind?: EventKindType, form: FormInstance}> = ({eventKind, form}) => {
  if (eventKind === 'Location') {
    return (
      <>
        <div className={classes.label}>Event Location</div>
        <InputGoogleAPI
          placeholder='Enter Address'
          suggestionsStyle={{maxHeight: '150px', overflowY: 'auto'}}
          type='locality'
          value={form.getFieldValue('locationName')}
          onSelect={(value: string) => {
            form.setFields([
              {
                name: 'locationName',
                value: value,
                errors: []
              }
            ]);
          }}
          style={{width: '100%'}}
        />
        <Form.Item
          name={'locationName'}
          rules={[{required: true, message: 'This field is required'}]}
          style={{height: '0px'}}
        />
      </>
    )
  } else if (eventKind === 'Cruise') {
    return (
      <>
        <div className={classes.label}>Event Cruise</div>
        <CruiseEventFields form={form}/>
      </>
    )
  } else {
    return <></>
  }
}



export default EventDetailsPage
