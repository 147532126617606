import React, { useCallback, useEffect } from 'react'
import { Select } from 'antd'
import classes from './InputGoogleAPI.module.css'
import { debounce } from 'lodash'
import { locationAPI } from '../../../app/api'

interface GoogleDataInputPropsType {
  placeholder: string
  onChange?: (value: string) => void
  onSelect?: (value: string) => void
  value?: string
  suggestionsPosition?: 'vertical' | 'horizontal'
  style?: object
  suggestionsStyle?: object
  disabled?: boolean
  clearAfterSelect?: boolean
  status?: 'error'
  type?: 'locality' | 'country' // return only cities or countries
}

export const InputGoogleAPI = (props: GoogleDataInputPropsType) => {
  const [address, setAddress] = React.useState('')
  const [options, setOptions] = React.useState<string[]>([])

  const handleSelect = async (value: string) => {
    if (value?.length) {
      props.onSelect && await props.onSelect(value)
      setAddress(props.clearAfterSelect ? '' : value)
      setOptions([])
    }
  }

  const handleChange = (value: string) => {
    if (!value.length) {
      props.onChange && props.onChange(value)
      setOptions([])
    } else {
      debouncedFetchData(value)
    }
    setAddress(value)
  }

  const fetchData = async (query: string) => {
    try {
      const {data, status} = await locationAPI.getPlacesAutocomplete(query, props.type)
      if (status === 200) {
        const locOptions = data?.list as string[]
        setOptions(locOptions)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  // eslint-disable-next-line
  const debouncedFetchData = useCallback(debounce(fetchData, 1000), [])

  useEffect(() => {
    setAddress(props.value || '')
  }, [props.value])

  return (
    <div
      className={`${classes.wrapper}`}
      id='googleAutocomplete'
    >
      <Select
        aria-label={'search'}
        showSearch
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={null}
        suffixIcon={null}
        searchValue={address}
        labelRender={() => !!props.value?.length
          ? <>{props.value}</>
          : <span style={{color: '#36374a80'}}>{props.placeholder}</span>}
        onSearch={handleChange}
        onSelect={handleSelect}
        style={props.style}
        placeholder={props.placeholder}
        status={props.status}
        disabled={props.disabled}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            e.preventDefault()
          }
        }}
        getPopupContainer={() => document.getElementById('googleAutocomplete')!}
        options={options?.map(o => ({label: o, value: o}))}
        className={classes.select}
        placement='bottomLeft'
        dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
        // dropdownStyle={{bottom: '10px', zIndex: 99999999}}
        // allowClear={!!props.onClear}
        // onClear={props.onClear}
      />
      {/* {!!props?.error?.length &&
        <div className={classes.error}>
          {props.error}
        </div>
      } */}
    </div>

  )
}
