import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import classes from './AdvertisingDetailsForm.module.css'
import { CreateAdvertisementThunk, EditAdvertisementThunk, GetAdvertisementByIdThunk, GetAdvertisementTargetDataThunk, defaultTargetDataPagination, selectCurrentAdvertisement, selectTargetDataPagination, setCurrentAdvertisement, setTargetDataPagination, setTargetDataTotalCount } from '../../../store/advertisingReducer'
import { Button, Form, Radio, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { AdvertisementEditingType } from '../../../types/advertisingTypes'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import MainData from './MainData/MainData'
import TargetingAudience from './TargetingAudience/TargetingAudience'
import AdvertisingPlacement from './AdvertisingPlacement/AdvertisingPlacement'
import { ConnectionOptions } from '../../Services/ServiceDetailsForm/ServiceDetailsForm'
import { getAdvertisingLocationsValue, getConnectionValidationError } from '../../../helpers/serviceConnectionsHelper'
import { pickBy } from 'lodash'
import { GetAllCountriesThunk, GetDistanceUnitsThunk } from '../../../store/locationsReducer'
import dayjs from 'dayjs'
import { isValidHTML } from '../../../helpers/files_helper'
import { ImagePreviewType } from '../../../types/appTypes'
import axios from './../../../helpers/axiosHelper'

const AdvertisingDetailsForm: React.FC<{isEditing: boolean, isCopy: boolean}> = ({isEditing, isCopy}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentAdvertisement = useAppSelector(selectCurrentAdvertisement)
  const targetDataPagination = useAppSelector(selectTargetDataPagination)

  const [form] = Form.useForm()
  const searchType = Form.useWatch('search_type', form)
  const isSpecialOffer = Form.useWatch('is_special_offer', form)

  const [isSaving, setIsSaving] = useState(false)
  const [selectedConnectionOption, setSelectedConnectionOption] = useState(ConnectionOptions.Nodes)
  const [error, setError] = useState('')
  const [selectedBannerOption, setSelectedBannerOption] = useState<'uploaded' | 'generated' | 'html'>('uploaded')
  const [uploadedBannerPreview, setUploadedBannerPreview] = useState<null | ImagePreviewType>(null)
  const [isTargetingFieldsDisabled, setIsTargetingFieldsDisabled] = useState(false)

  const checkRequiredPlacement = (formValues: any) => {
    if (formValues.selected_banner_type === 'html' && !isValidHTML(formValues.banner_html)) {
      return 'Your HTML code is invalid!'
    } else if ((formValues.selected_banner_type === 'uploaded' || formValues.selected_banner_type === 'generated') && !formValues.banner && !formValues?.banner_url && !isSpecialOffer) {
      return 'Please upload your banner!'
    } else if (!formValues.place_type && !((formValues?.search_type === 'event' || formValues?.search_type === 'rental') && formValues?.is_special_offer)) {
      return 'Please select advertisement placement!'
    }
  }

  const changeConnectionOption = (option: ConnectionOptions) => {
    setSelectedConnectionOption(option)
    form.setFieldValue('added_to_copy_node_id_list', [])
  }

  const sendData = async(formData: any) => {
    if (isEditing) {
      return dispatch(EditAdvertisementThunk({id: currentAdvertisement?.advertising_id!, updatedData: formData}))
    } else {
      return dispatch(CreateAdvertisementThunk(formData))
    }
  }

  const handleSave = async() => {
    let formValues = form.getFieldsValue(true)
    const isTheSameSpecialOffer = !!isCopy && formValues?.special_offer?.id === currentAdvertisement?.advertising_locations[0]?.special_offer?.id
    const error = getConnectionValidationError(formValues, selectedConnectionOption, ConnectionOptions, searchType === 'search' ? undefined : searchType) || checkRequiredPlacement(formValues)
    if (error?.length) {
      setError(error) 
      return
    } else {
      setError('')
    }
    const advertisementData = {
      ...pickBy(formValues, (_, key) => formValues[key] !== undefined
        && key !== 'node_id_list'
        && key !== 'nodes'
        && key !== 'allowed_user_location'
        && key !== 'banner'
        && key !== 'is_global'
        && key !== 'search_type'
        && key !== 'exclude_advertising_location_id_list'
        && key !== 'added_to_copy_node_id_list'
        && key !== 'node_type'
        && key !== 'place_id'
        && key !== 'is_special_offer'
        && key !== 'special_offer'
        && key !== 'advertisement'
      ),
      advertising_id: isEditing ? currentAdvertisement?.advertising_id : undefined,
      advertising_locations: getAdvertisingLocationsValue(formValues, isCopy)
        .map((loc:any) => ({
          ...loc,
          search_type: formValues.search_type,
          ...(formValues?.is_special_offer && isEditing ? {advertising_location_id: currentAdvertisement?.advertising_locations[0]?.advertising_location_id} : {}),
          ...(formValues.search_type === 'cruise' ? {node_type: 'Cruise'} : {}),
          ...(formValues.search_type === 'event' || formValues.search_type === 'rental' ? {
            ...(isTheSameSpecialOffer
              ? {...currentAdvertisement?.advertising_locations[0], advertising_location_id: undefined}
              : {}
            ),
            node_type: formValues.search_type === 'event' ? 'Event' : 'Rental',
            location_type: formValues.search_type === 'event' ? 'event' : 'rental',
            is_special_offer: formValues?.is_special_offer,
            special_offer: formValues?.is_special_offer
              ? {
                ...formValues?.special_offer,
                id: !!isEditing ? formValues?.special_offer?.id : undefined,
                offer_type: undefined,
                offer_type_comment: '',
                company_name: formValues?.company_name,
                url: formValues?.link,
                phone: !!formValues?.special_offer?.phone?.phoneNumber
                  ? `${formValues?.special_offer?.phone?.countryCode}${formValues?.special_offer?.phone?.areaCode}${formValues?.special_offer?.phone?.phoneNumber}`
                  : !!formValues?.special_offer?.phone?.length ? formValues?.special_offer?.phone : ''
              }
              : undefined
          } : {})
        })),
      user_locations: formValues?.allowed_user_location,
      start_date: formValues?.start_date.startOf('day').format(),
      end_date: formValues?.end_date.endOf('day').format()
    }
    const formData = new FormData()
    formData.append('advertising', new Blob([JSON.stringify(advertisementData, null, 2)], {type: 'application/json'}))
    !!formValues?.banner && formData.append('banner', formValues?.banner?.originFileObj)

    if (isCopy) {
      const copyNodes = formValues?.exclude_advertising_location_id_list !== null && !isTheSameSpecialOffer
      const copyBannerLink = !formValues?.banner && !!formValues?.banner_url
      if (copyNodes || copyBannerLink) {
        const advertisingSettings = {
          old_advertising_id: currentAdvertisement?.advertising_id,
          use_old_banner: copyBannerLink,
          use_old_node: copyNodes,
          ...(copyNodes
            ? {
              start_date: formValues?.start_date.startOf('day').format(),
              end_date: formValues?.end_date.endOf('day').format(),
              exclude_advertising_location_id_list: formValues?.exclude_advertising_location_id_list || [],
            }
            : {}
          ),
          ...(isTheSameSpecialOffer ? {exclude_advertising_location_id_list: [currentAdvertisement?.advertising_locations[0]?.advertising_location_id]} : {})
        }
        formData.append('advertising_settings', new Blob([JSON.stringify(advertisingSettings, null, 2)], {type: 'application/json'}))
      }
    }
    setIsSaving(true)
    sendData(formData)
      .then((resp) => {
        setIsSaving(false)
        if (!resp?.type.includes('rejected')) {
          dispatch(setCurrentAdvertisement({} as AdvertisementEditingType))
          navigate('/advertising')
        }
      })
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if (isEditing || isCopy) {
      // pathname example: /advertising/edit/47895 | /advertising/create/copy/25
      const id = location.pathname.split(isEditing ? 'edit/' : 'copy/')[1]
      dispatch(GetAdvertisementByIdThunk({id: +id, withLocations: false, source}))
      dispatch(GetAdvertisementTargetDataThunk({id: +id, pagination: targetDataPagination, source}))
    }
    dispatch(GetDistanceUnitsThunk())
    dispatch(GetAllCountriesThunk())
    return () => {
      dispatch(setCurrentAdvertisement({} as AdvertisementEditingType))
      dispatch(setTargetDataPagination(defaultTargetDataPagination))
      dispatch(setTargetDataTotalCount(0))
      source.cancel()
    }
    // eslint-disable-next-line
  }, [isEditing, isCopy, dispatch])

  useEffect(() => {
    if ((isEditing || isCopy) && !!Object.keys(currentAdvertisement || {})?.length && !!currentAdvertisement?.advertising_locations?.length) {
      let advertisementFieldsData
      if (isEditing) {
        const originalStartDate = new Date(dayjs(currentAdvertisement?.start_date).format())
        const startLocalDate = new Date(originalStartDate.getTime() - originalStartDate.getTimezoneOffset() * 60000)
        const originalEndDate = new Date(dayjs(currentAdvertisement?.end_date).format())
        const endLocalDate = new Date(originalEndDate.getTime() - originalEndDate.getTimezoneOffset() * 60000)
        advertisementFieldsData = {
          ...currentAdvertisement,
          start_date: dayjs(startLocalDate.toISOString()),
          end_date: dayjs(endLocalDate.toISOString()),
          search_type: currentAdvertisement?.advertising_locations[0]?.search_type || 'search',
          is_special_offer: currentAdvertisement?.advertising_locations[0]?.is_special_offer,
          special_offer: {
            ...currentAdvertisement?.advertising_locations[0]?.special_offer,
            offer_type_id: currentAdvertisement?.advertising_locations[0]?.special_offer?.offer_type?.id
          }
        }
      } else {
        advertisementFieldsData = {
          ...currentAdvertisement,
          start_date: undefined,
          end_date: undefined,
          search_type: currentAdvertisement?.advertising_locations[0]?.search_type || 'search',
          place_type: undefined,
          place_index: undefined,
          exclude_advertising_location_id_list: currentAdvertisement?.advertising_locations[0].location_type !== 'node' ? null : [],
          is_special_offer: currentAdvertisement?.advertising_locations[0]?.is_special_offer,
          special_offer: {
            ...currentAdvertisement?.advertising_locations[0]?.special_offer,
            offer_type_id: currentAdvertisement?.advertising_locations[0]?.special_offer?.offer_type?.id
          }
        }
      }
      form.setFieldsValue(advertisementFieldsData)
      setSelectedBannerOption(currentAdvertisement?.selected_banner_type || 'uploaded')
      if (currentAdvertisement?.advertising_locations[0].location_type === 'node') {
        if (currentAdvertisement?.advertising_locations[0].node_type.toLowerCase() === 'city') {
          setSelectedConnectionOption(ConnectionOptions.City)
        } else if (currentAdvertisement?.advertising_locations[0].node_type.toLowerCase() === 'country') {
          setSelectedConnectionOption(ConnectionOptions.Country)
        } else {
          setSelectedConnectionOption(ConnectionOptions.Nodes)          
        }
      } else if (currentAdvertisement?.advertising_locations[0].location_type === 'coordinates'
        || currentAdvertisement?.advertising_locations[0]?.radius_area?.latitude
      ) {
        setSelectedConnectionOption(ConnectionOptions.Coordinates)
      } else if (currentAdvertisement?.advertising_locations[0].location_type === 'rental') {
        setSelectedConnectionOption(ConnectionOptions.Nodes)  
      } else {
        setSelectedConnectionOption(ConnectionOptions.Global)
      }
    }
  }, [currentAdvertisement, form, isEditing, isCopy, dispatch])

  if (isEditing && !Object.keys(currentAdvertisement || {})?.length && !currentAdvertisement?.advertising_locations?.length
  ) {
    return <Spin className={classes.spinner} />
  }
  return (
    <Form
      name='advertisement'
      onFinish={handleSave}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
      initialValues={{
        banner_color: 'black',
        banner_bg_color: '#e5e5e5',
        selected_banner_type: 'uploaded',
        search_type: 'search',
        is_special_offer: false
      }}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentAdvertisement?.company_name : 'Adding a new advertisement'}
        </h1>
        <MainData
          form={form}
          isEditing={isEditing}
          isCopy={isCopy}
          selectedBannerOption={selectedBannerOption}
          setSelectedBannerOption={setSelectedBannerOption}
          uploadedBannerPreview={uploadedBannerPreview}
          setUploadedBannerPreview={setUploadedBannerPreview}
          isTargetingFieldsDisabled={isTargetingFieldsDisabled}
        />
        <div className={classes.formBlock}>
          <div className={classes.blockTitle}>
            Select search type
          </div>
          <div className={classes.label} style={{marginBottom: '10px'}}>
            Select the type of search where the advertisement will be placed:
          </div>
          <Form.Item name='search_type' style={{marginBottom: '0px'}}>
            <Radio.Group
              disabled={isEditing || isTargetingFieldsDisabled}
            >
              <Radio value='search'>Search</Radio>
              <Radio value='cruise'>By Cruise</Radio>
              <Radio value='event'>Event</Radio>
              <Radio value='rental'>Rental</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <TargetingAudience
          form={form}
          isEditing={isEditing}
          isCopy={isCopy}
          selectedConnectionOption={selectedConnectionOption}
          setSelectedConnectionOption={changeConnectionOption}
          setError={setError}
          isTargetingFieldsDisabled={isTargetingFieldsDisabled}
        />
        {!(isSpecialOffer && (searchType === 'event' || searchType === 'rental')) &&
          <AdvertisingPlacement
            form={form}
            selectedConnectionOption={selectedConnectionOption}
            isEditing={isEditing}
            isCopy={isCopy}
            selectedBannerOption={selectedBannerOption}
            uploadedBannerPreview={uploadedBannerPreview}
            setIsTargetingFieldsDisabled={setIsTargetingFieldsDisabled}
          />
        }
      </div>

      {!!error.length && <div className={classes.error}>{error}</div>}

      {/* {!isEditing && */}
        <div className={classes.buttons}>
          <Link to='/advertising'>
            <Button>
              Cancel
            </Button>
          </Link>
            <Button
              type='primary'
              htmlType='submit'
              loading={isSaving}
            >
              Save
            </Button>
        </div>
      {/* } */}
    </Form>
  )
}


export default AdvertisingDetailsForm
