import {Link} from 'react-router-dom'
import {Popconfirm, Popover, Table, Tooltip} from 'antd'
import classes from './ItemList.module.css'
import {ReactComponent as EditIcon} from './../../../img/icons/edit.svg'
import {ReactComponent as ViewIcon} from './../../../img/icons/view.svg'
import {ReactComponent as DeleteIcon} from './../../../img/icons/delete.svg'
import {ReactComponent as CopyIcon} from './../../../img/icons/copy.svg'
import redDot from './../../../img/icons/redDot.png'
import {PaginationRequestTypes} from '../../../types/appTypes'
import {Dispatch, SetStateAction} from 'react'
import {AirportType} from '../../../types/airportTypes'
import {ServiceType} from '../../../types/serviceTypes'
import dayjs from 'dayjs'

const ItemList: React.FC<ItemListProps> = ({
                                               items,
                                               loading,
                                               editItemLink,
                                               getEditItemLink,
                                               viewItemLink,
                                               getViewItemLink,
                                               deleteItem,
                                               copyItemLink,
                                               pagination,
                                               setPagination,
                                               total,
                                               columnList,
                                               rowIdKey,
                                               transportType,
                                               getAdditionalColumns,
                                               rowSelection
                                           }) => {
    const getColumnSortOrder = (columnName: string) => {
        const currentPagination = pagination?.sort?.find(column => column?.field === columnName)?.direction
        if (currentPagination?.length) {
            return currentPagination === 'ASC' ? 'ascend' : 'descend'
        } else {
            return false
        }
    }

    const setColumnSortOrder = (columnName: string, currentSortOrder: 'descend' | 'ascend' | false) => {
        if (setPagination && pagination) {
            if (currentSortOrder === 'ascend') {
                setPagination({...pagination, page: 1, sort: [{direction: 'DESC', field: columnName}]})
            } else if (currentSortOrder === 'descend') {
                setPagination({page: 1, size: pagination.size})
            } else {
                setPagination({...pagination, page: 1, sort: [{direction: 'ASC', field: columnName}]})
            }
        }
    }

    const columns = [
        ...(columnList.some(item => item === 'name') ? [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('name'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('name', column.sortOrder)
            }),
            render: (name: string) => (
                <div className={classes.mainColumn}>{name}</div>
            ),
        }] : []),
        ...(columnList.some(item => item === 'country_name') ? [{
            title: 'Country Name',
            dataIndex: 'country_name',
            key: 'country_name',
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'type_name') ? [{
            title: 'Type',
            dataIndex: 'type_name',
            key: 'type_name',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('type_name'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('type_name', column.sortOrder)
            }),
        }] : []),
        ...(columnList.some(item => item === 'headquarters') ? [{
            title: 'Headquarters',
            dataIndex: 'headquarters',
            key: 'headquarters',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('headquarters'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('headquarters', column.sortOrder)
            }),
        }] : []),
        ...(columnList.some(item => item === 'code') ? [{
            title: transportType === 'airport' ? 'IATA' : 'Code',
            dataIndex: 'code',
            key: 'code',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('code'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('code', column.sortOrder)
            }),
        }] : []),
        ...(columnList.some(item => item === 'icao') ? [{
            title: 'ICAO',
            dataIndex: 'icao',
            key: 'icao',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('icao'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('icao', column.sortOrder)
            }),
        }] : []),
        ...(columnList.some(item => item === 'latitude') ? [{
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'longitude') ? [{
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'state_code') ? [{
            title: 'State Code',
            dataIndex: 'state_code',
            key: 'state_code',
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'state_name') ? [{
            title: 'State Name',
            dataIndex: 'state_name',
            key: 'state_name',
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'alias') ? [{
            title: 'Alias',
            dataIndex: 'alias',
            key: 'alias',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('alias'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('alias', column.sortOrder)
            }),
        }] : []),
        ...(columnList.some(item => item === 'display_name') ? [{
            title: 'Display Name',
            dataIndex: 'display_name',
            key: 'display_name',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('display_name'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('display_name', column.sortOrder)
            })
        }] : []),
        ...(columnList.some(item => item === 'location') ? [{
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'ranking') ? [{
            title: 'Ranking',
            dataIndex: 'ranking',
            key: 'ranking',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('ranking'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('ranking', column.sortOrder)
            })
        }] : []),
        ...(columnList.some(item => item === 'phones') ? [{
            title: 'Phones',
            dataIndex: 'phones',
            key: 'phones',
            ellipsis: true,
            render: (phones: string[]) => (
                <div>{phones?.map(phone => <div key={phone}>{phone}</div>)}</div>
            ),
        }] : []),
        ...(columnList.some(item => item === 'website') ? [{
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
            ellipsis: true,
            render: (text: string) => (
                <a className={text?.length ? classes.link : ''} href={text} target='_blank' rel='noreferrer'>
                    {text}
                </a>
            ),
        }] : []),
        ...(columnList.some(item => item === 'is_operated') ? [{
            title: 'Operated',
            dataIndex: 'is_operated',
            key: 'is_operated',
            ellipsis: true,
            render: (operating: boolean) => (
                <div>{!!operating ? 'Yes' : operating === null ? '' : 'No'}</div>
            ),
        }] : []),
        ...(columnList.some(item => item === 'node_type') ? [{
            title: 'Node Type',
            dataIndex: 'node',
            key: 'node',
            render: (node: any) => (
                <div className={classes.mainColumn}>{node?.label}</div>
            ),
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'node_name') ? [{
            title: 'Node Name',
            dataIndex: 'node',
            key: 'node',
            render: (node: any) => (
                <div className={classes.mainColumn}>{node?.name}</div>
            ),
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'node_code') ? [{
            title: 'Node Code',
            dataIndex: 'node',
            key: 'node',
            render: (node: any) => (
                <div className={classes.mainColumn}>{node?.code}</div>
            ),
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'comment') ? [{
            title: 'Additional information',
            dataIndex: 'comment',
            key: 'comment',
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'country.name') ? [{
            title: 'Country name',
            dataIndex: 'country',
            key: 'country',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('country.name'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('country.name', column.sortOrder)
            }),
            render: (country: any) => (
                <div className={classes.mainColumn}>{country ? country.name : ''}</div>
            ),
        }] : []),
        ...(columnList.some(item => item === 'category') ? [{
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            ellipsis: true,
            sorter: true,
            sortOrder: getColumnSortOrder('category'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('category', column.sortOrder)
            }),
            render: (category: any) => (

                <div className={classes.mainColumn}>
                    {category === null ? 'Category not defined yet'
                        : category === -1 ? `Don't display`
                            : category === 0 ? 'No scheduled service'
                                : category === 1 ? `Small ${transportType}`
                                    : category === 2 ? `Medium ${transportType}`
                                        : category === 3 ? `Large ${transportType} (hub)`
                                            : null}
                </div>

            ),
        }] : []),
        ...(columnList.some(item => item === 'action_date') ? [{
            title: 'Search Date',
            dataIndex: 'action_date',
            key: 'action_date',
            sorter: true,
            sortOrder: getColumnSortOrder('action_date'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('action_date', column.sortOrder)
            }),
            render: (action_date: any) => <div>{action_date?.split('T')[0]}</div>,
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'action_date') ? [{
            title: 'Search time',
            dataIndex: 'action_date',
            key: 'action_date',
            render: (action_date: any) => (
                <div>{dayjs(action_date).format('H:mm:ss')}</div>
            ),
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'computer_city') ? [{
            title: 'User Location',
            dataIndex: 'computer_city',
            key: 'computer_city',
            sorter: true,
            sortOrder: getColumnSortOrder('computer_city'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('computer_city', column.sortOrder)
            }),
            render: (_: any, row: any) => (
                <div>{row?.computer_city ? row?.computer_city + ', ' : ''} {row?.computer_country}</div>
            ),
            ellipsis: true,
        }] : []),
        ...(columnList.some(item => item === 'searchedAddress') ? [{
            title: 'Searched Address',
            dataIndex: 'searchedAddress',
            key: 'searchedAddress',
            sorter: true,
            sortOrder: getColumnSortOrder('searched_address'),
            onHeaderCell: (column: any) => ({
                onClick: () => setColumnSortOrder('searched_address', column.sortOrder)
            }),
            ellipsis: true,
        }] : []),
        ...(!!getAdditionalColumns ? getAdditionalColumns(getColumnSortOrder, setColumnSortOrder) : []),
        ...(columnList.some(item => item === 'actions') ? [{
            title: 'Actions',
            key: 'actions',
            render: (_: string, row: any) => {
                return (
                    <div className={classes.actions}>
                        {(viewItemLink || getViewItemLink) &&
                            <Link to={!!viewItemLink ? viewItemLink + row?.[rowIdKey] : getViewItemLink!(row)} style={{height: '20px', display: 'inline-block'}}>
                                <ViewIcon style={{height: '100%'}}/>
                            </Link>
                        }
                        {(editItemLink || getEditItemLink) &&
                            <Link
                                to={editItemLink ? editItemLink + row?.[rowIdKey] : getEditItemLink!(row?.[rowIdKey])}
                                // style={{marginLeft: row?.is_approved === false ? '' : '20px'}}
                            >
                                <EditIcon/>
                            </Link>
                        }
                        {!!copyItemLink &&
                            <Popover
                                content={
                                    <div style={{width: '180px',textAlign: 'center', fontSize: '14px'}}>
                                        Create a new advertisement based on this ad data
                                    </div>
                                }
                            >
                                <Link to={copyItemLink + row?.[rowIdKey]}>
                                    <CopyIcon/>
                                </Link>
                            </Popover>
                        }
                        {!!deleteItem &&
                            <Popconfirm
                                title='Are you sure to delete this item?'
                                onConfirm={() => deleteItem(row)}
                                okText='Yes'
                                cancelText='No'
                                placement='left'
                                >
                                <DeleteIcon />
                            </Popconfirm>
                        }
                        {row?.is_approved === false &&
                            <Tooltip title='Data needs to be approved'>
                                <img src={redDot} alt='notApproved' className={classes.notApprovedMarker}/>
                            </Tooltip>
                        }
                    </div>
                )
            },
            width: '130px',
        },
    ] : []),
    ]

    return (
        <Table
            // @ts-ignore
            columns={columns}
            dataSource={items}
            // @ts-ignore
            rowKey={(record: ItemType) => record?.[rowIdKey]}
            loading={loading}
            rowSelection={rowSelection}
            pagination={pagination
                ? {
                    position: ['bottomRight'],
                    current: pagination.page,
                    pageSizeOptions: [10, 20, 30],
                    pageSize: pagination.size,
                    hideOnSinglePage: true,
                    showSizeChanger: true,
                    total: total,
                    onChange: (page, size) => setPagination!({...pagination, page, size: size as 10 | 20 | 30})
                } : false
            }
        />
    )
}

interface ItemListProps {
    items: any[]
    editItemLink?: string
    getEditItemLink?: (id: number) => string
    viewItemLink?: string
    getViewItemLink?: (item: any) => string
    deleteItem?: (arg: any) => void
    copyItemLink?: string
    loading?: boolean
    pagination?: PaginationRequestTypes
    setPagination?: Dispatch<SetStateAction<PaginationRequestTypes>>
    total: number
    columnList: string[]
    rowIdKey: string
    transportType?: string,
    getAdditionalColumns?: GetAdditionalColumnType
    rowSelection?: {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => any
        getCheckboxProps?: (record: any) => any,
        selectionType: 'checkbox' | 'radio'
    }
}

type ItemType = AirportType | ServiceType

export type GetAdditionalColumnType = (
    getColumnSortOrder: (columnName: string) => false | 'descend' | 'ascend',
    setColumnSortOrder: (columnName: string, currentSortOrder: 'descend' | 'ascend' | false) => void
) => any[]

export default ItemList
