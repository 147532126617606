import { ConfigProvider, message } from 'antd'
import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import NavigationPanel from '../components/NavigationPanel/NavigationPanel'
import { AppStatusType, selectAppStatus, selectErrorMessage, selectSuccessMessage } from '../store/appStatusReducer'
import { selectIsLoggedIn } from '../store/userReducer'
import './App.less'
import { useAppSelector } from './hooks'
import routes from './routes'

const antTheme = {
  token: {
    fontFamily: '"Nunito Sans", sans-serif',
    colorPrimary: '#3a36db',
    borderRadius: 10,
    fontSize: 18,
  },
  components: {
    Button: {
      controlHeight: 48,
      fontSize: 18,
      fontWeight: 500,
      primaryShadow: 'transparent',
    },
    Input: {
      controlHeight: 48,
      fontSize: 18,
      colorTextPlaceholder: '#7b809a',
    },
    InputNumber: {
      controlHeight: 48,
      fontSize: 18,
      colorTextPlaceholder: '#7b809a',
    },
    Select: {
      controlHeight: 48,
      fontSize: 18,
      colorTextPlaceholder: '#7b809a',
    },
    DatePicker: {
      controlHeight: 48,
      fontSize: 18,
      colorTextPlaceholder: '#7b809a',
    },
    Radio: {
      borderRadius: 10,
    },
    Upload: {
      padding: 0,
    },
    Checkbox: {
      colorPrimary: '#3a36db',
      fontSize: 18,
    },
    Tabs: {
      fontSize: 18,
    },
    Table: {
      headerColor: '#4f5b67',
      headerBg: 'white',
      fontSize: 12,
    }
  },
}

function App() {
  const appStatus = useAppSelector(selectAppStatus)
  const error = useAppSelector(selectErrorMessage)
  const successMessage = useAppSelector(selectSuccessMessage)

  useEffect(() => {
    appStatus === AppStatusType.failed && message.error(error, 2)
    appStatus === AppStatusType.succeeded && message.success(successMessage, 2)
  }, [appStatus, error, successMessage])

  return (
    <ConfigProvider theme={antTheme}>
      <div className='app'>
        <Routes>
          {routes.map((route, index) => {
            return route.auth ? (
              <Route key={index} path={route.path} element={<PrivateRoute/>}>
                <Route path={route.path} element={<route.component {...route?.props} />}/>
              </Route>
            ) : (
              <Route key={index} element={<route.component {...route?.props} />} path={route.path}/>
            )
          })}
        </Routes>
      </div>
    </ConfigProvider>
  )
}

const PrivateRoute = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  return isLoggedIn ? (
    <div className='privateRoute' style={{position: 'relative'}}>
      <NavigationPanel />
      <div>
      </div>
      <div className='appContent'>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to='/sign-in' />
  )
}

export default App
