import { Button, Checkbox, Form, Select, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classes from './CruiseLineDetailsForm.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CreateCruiseLineThunk, EditCruiseLineThunk, GetCruiseLineByIdThunk, GetCruiseLineTypesThunk, selectCruiseLineTypes, selectCurrentCruiseLine, setCurrentCruiseLine } from '../../../store/cruiseLinesReducer'
import { CruiseLineType } from '../../../types/cruiseType'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import InputField from '../../common/formFields/InputField'
import { sortBy, pickBy } from 'lodash'

const CruiseLineDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentCruiseLine = useAppSelector(selectCurrentCruiseLine)

  const [form] = Form.useForm()

  const [isSaving, setIsSaving] = useState(false)

  const sendData = async(formData: any) => {
    if (isEditing) {
      return dispatch(EditCruiseLineThunk(formData))
    } else {
      return dispatch(CreateCruiseLineThunk(formData))
    }
  }

  const handleSave = async() => {
    let formValues = form.getFieldsValue(true)
    setIsSaving(true)
    sendData(pickBy(formValues, (_, key) => key !== 'type_name'))
      .then((resp) => {
        setIsSaving(false)
        !resp?.type.includes('rejected') && navigate('/cruise-lines')
      })
  }

  useEffect(() => {
    if (isEditing) {
      // pathname example: /cruise-lines/edit/47895
      const id = location.pathname.split('edit/')[1]
      dispatch(GetCruiseLineByIdThunk(+id))
    }
    return () => {
      dispatch(setCurrentCruiseLine({} as CruiseLineType))
    }
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && !!Object.keys(currentCruiseLine || {})?.length) {
      const cruiseLineFieldsData = {
        ...currentCruiseLine,
      }
      form.setFieldsValue(cruiseLineFieldsData)
    }
  }, [currentCruiseLine, form, isEditing, dispatch])

  if (isEditing && !Object.keys(currentCruiseLine || {})?.length) {
    return <Spin className={classes.spinner} />
  }
  return (
    <Form
      name='cruise_line'
      onFinish={handleSave}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
      initialValues={{
        website: '',
        is_operated: false,
        comment: ''
      }}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentCruiseLine?.name : 'Adding a new cruise line'}
        </h1>
        <DetailsForm />
      </div>
      <div className={classes.buttons}>
        <Link to='/cruise-lines'>
          <Button>
            Cancel
          </Button>
        </Link>
          <Button
            type='primary'
            htmlType='submit'
            loading={isSaving}
          >
            Save
          </Button>
      </div>
    </Form>
  )
}

const DetailsForm = () => {
  const dispatch = useAppDispatch()
  const cruiseLineTypes = useAppSelector(selectCruiseLineTypes)

  useEffect(() => {
    !cruiseLineTypes?.length && dispatch(GetCruiseLineTypesThunk())
  }, [dispatch, cruiseLineTypes])

  return (
    <div className={classes.formAreaWrapper}>
      <div className={classes.formBlock}>
        <InputField
          name='name'
          requiredMessage={`Please enter cruise line name!`}
          placeholder='Enter Name'
          label={`Cruise Line Name`}
        />
        <InputField
          name='code'
          // requiredMessage={`Please enter cruise line code!`}
          placeholder='Enter Code'
          label='Code'
        />
        <div className={classes.label}>Cruise Line Type</div>
        <Form.Item
          name='type_id'
          rules={[{ required: true, message: 'Please select cruise line type!' }]}
        >
          <Select placeholder='Please select cruise line type'>
            {sortBy(cruiseLineTypes, lineType => lineType.name).map(lineType => (
              <Select.Option value={lineType.id} key={lineType.id}>{lineType.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <InputField
          name='headquarters'
          requiredMessage={`Please enter headquarters!`}
          placeholder='Enter Headquarters'
          label='Headquarters'
        />
        <InputField
          name='website'
          placeholder='Enter Website'
          label='Website'
        />
        <Form.Item
          name='is_operated'
          valuePropName='checked'
          style={{margin: '10px 0px', border: '1px solid #d9d9d9', borderRadius: '10px', padding: '10px 0px'}}>
          <Checkbox style={{marginLeft: '10px'}}>
            Operated
          </Checkbox>
        </Form.Item>
      </div>
      <div className={classes.formBlock} style={{height: '250px'}}>
        <InputField
          name='comment'
          placeholder='Enter Comment'
          label='Comment'
          rows={4}
        />
      </div>
    </div>
  )
}

export default CruiseLineDetailsForm
