import { Button, Input } from 'antd'
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import axios from './../../helpers/axiosHelper'
import classes from './BlogList.module.css'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DeleteBlogEntryThunk, GetAllBlogEntriesThunk, selectBlogEntries, selectBlogEntriesFetchParams, selectBlogEntriesTotalCount, setBlogEntriesFetchParams } from '../../store/blogReducer'
import { BlogEntriesFetchParams, BlogEntryType } from '../../types/blogTypes'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import { slugify } from '../../helpers/funcHelper'

const BlogList = () => {
  const dispatch = useAppDispatch()
  const blogEntries = useAppSelector(selectBlogEntries)
  const blogEntriesTotalCount = useAppSelector(selectBlogEntriesTotalCount)
  const fetchParams = useAppSelector(selectBlogEntriesFetchParams)

  const [tableData, setTableData] = useState<BlogEntryType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllBlogEntriesThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(blogEntries || [])
  }, [blogEntries])

  const deleteItem = (blogEntry: BlogEntryType): void => {
    dispatch(DeleteBlogEntryThunk(blogEntry.id))
  }

  const setFetchParams = (params: BlogEntriesFetchParams) => {
    dispatch(setBlogEntriesFetchParams(params))
  }
  
  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, blog_entity_request: {search: encodeURI(searchRequest)}, pagination_request: {...fetchParams.pagination_request, page: 1}})
    }, 350),
    [fetchParams]
  )

  const getEditItemLink = (id: number) => `/blog/edit/${id}`

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Blog
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.blog_entity_request.search as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
          <Link to='/blog/create'>
            <Button type='primary'>+ Add New</Button>
          </Link>
        </div>
      </div>

      <ItemList
        items={tableData}
        getEditItemLink={getEditItemLink}
        deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination_request}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination_request: pagination})}
        total={blogEntriesTotalCount}
        columnList={['actions']}
        rowIdKey='id'
        getAdditionalColumns={getAdditionalTableColumns}
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  const getPostBaseUrl = () => {
    if (window.location.origin?.includes('scopia-admin-dev.esverito.com')) {
      return 'https://scopia-user-dev.esverito.com'
    } else if (window.location.origin?.includes('scopia-admin-test.esverito.pl')) {
      return 'https://scopia-user-test.esverito.com'
    } else {
      return 'https://www.relavanti.com'
    }
  }
  return ([
    {
      title: 'Post title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('title'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('title', column.sortOrder)
      }),
    },
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('created'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('created', column.sortOrder)
      }),
      render: (_:any, item: BlogEntryType) => (<>{dayjs(item.created).format('YYYY-MM-DD')}</>)
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      ellipsis: true,
      render: (_:any, item: BlogEntryType) => (
        <a
          className={classes.postLink}
          href={getPostBaseUrl() + '/blog/post/' + slugify(item.title)}
          target='_blank'
          rel='noreferrer'
        >
          {getPostBaseUrl() + '/blog/post/' + item.url}
        </a>
      )
    },
  ])
}

export default BlogList
